import React, { useState, useEffect } from 'react';
import {
  TopBarWraper,
  TopBar,
  Logo,
  TopIconBadge,
  RightContainer,
  TopBarIconContainer,
  NotificationBox,
  Card1,
} from './TopBarElem';
import authHeader from '../Login/auth-headers';

import { NotificationsNone } from '@material-ui/icons';
import { FiPower } from 'react-icons/fi';
import { FaBars } from 'react-icons/fa';

// import { toast } from "react-toastify";
//import {Menu}  from '@styled-icons/boxicons-regular/Menu'
import { ReactComponent as Pic } from '../../images/Group 1585.svg';
import { Link } from 'react-router-dom';
// import AuthService from "../Login/auth.service";
// import "react-toastify/dist/ReactToastify.css";
import urls from '../../Urls';
import { FaUser } from 'react-icons/fa';
import { IoIosBriefcase } from 'react-icons/io';
import Description from '../../Icons';
import './topbar.css';
import axios from 'axios';

// import { BorderRadius } from "styled-icons/boxicons-regular";
// toast.configure()

export default function Topbar() {
  // this.state ={
  //     users:null
  // }
  const [quotenotif1, setQuotenotif1] = useState(null);
  const [enquirynotif2, setEnquirynotif2] = useState(null);
  const [careernotif3, setCareernotif3] = useState(null);
  const [notif, setNotif] = useState(false);

  useEffect(() => {
    axios
      .get(
        urls + '/quoteNotification',

        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        setQuotenotif1(res.data.data[0].Total);
      });
    axios
      .get(
        urls + '/enquiryNotification',

        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        setEnquirynotif2(res.data.data[0].Total);
      });
    axios
      .get(
        urls + '/careerNotification',

        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        setCareernotif3(res.data.data[0].Total);
      });
  }, []);

  const notify = () => {
    setNotif(!notif);

    console.log('notify');
    if (notif) {
      document.getElementById('NotificationBox').style.display = 'none';
    } else {
      document.getElementById('NotificationBox').style.display = 'block';
    }
  };

  var time = new Date().toLocaleTimeString();
  var day = new Date().getDay();
  var date = new Date().getDay();
  const [time1, setTime1] = React.useState(time);
  const [cday, setCday] = React.useState(day);
  const [cdate, setCdate] = React.useState(date);

  const Updatetime = () => {
    let time = new Date().toLocaleTimeString();
    setTime1(time);
  };
  setInterval(Updatetime, 1000);

  const updateDate = () => {
    let date = new Date().toLocaleDateString();
    setCdate(date);
  };
  setInterval(updateDate, 1000);

  const updateday = () => {
    var day = new Date().getDay();
    var mon = 'Monday';
    var tue = 'Tuesday';
    var wed = 'Wednesday';
    var thur = 'Thursday';
    var fri = 'Friday';
    var sat = 'Saturday';
    var sun = 'Sunday';
    if (day === 1) {
      setCday(mon);
    } else if (day === 2) {
      setCday(tue);
    } else if (day === 3) {
      setCday(wed);
    } else if (day === 4) {
      setCday(thur);
    } else if (day === 5) {
      setCday(fri);
    } else if (day === 6) {
      setCday(sat);
    } else setCday(sun);
  };
  setInterval(updateday, 1000);

  const test = () => {
    document.getElementById('popupbox').style.display = 'block';
  };

  const dataSources = {
    group1: 'https://cerbosys.in:3700/cerbosys/quoteNotification',
    group2: 'https://cerbosys.in:3700/cerbosys/careerNotification',
    group3: 'https://cerbosys.in:3700/cerbosys/enquiryNotification',
  };

  return (
    <>
      {/* <!-- sticky bar parent start --> */}
      <div className="stickyTopBarParent">
        {/* <!-- sticky bar start --> */}
        <div className="stickyTopBar">
          <TopBar className="fixed ">
            <TopBarWraper className="fixed">
              <div
                style={{
                  color: '#EB641C',
                  paddingLeft: 40,
                }}
              >
                <FaBars style={{ fontSize: 28 }} />
              </div>
              <TopBarIconContainer style={{ paddingBottom: 20 }}>
                <Link to="/">
                  <Logo>
                    <Pic />
                  </Logo>
                </Link>
              </TopBarIconContainer>
              <TopBarIconContainer>
                <h1
                  style={{ fontSize: 15, fontWeight: 'bold', color: '#000000' }}
                >
                  {time1}&nbsp;&nbsp;{cdate}&nbsp;&nbsp;{cday}&nbsp;&nbsp;
                </h1>
              </TopBarIconContainer>
              <RightContainer>
                <TopBarIconContainer></TopBarIconContainer>
                <TopBarIconContainer>
                  <NotificationsNone />
                  <TopIconBadge onClick={notify}>
                    {quotenotif1 + enquirynotif2 + careernotif3}
                  </TopIconBadge>

                  <NotificationBox id="NotificationBox">
                    <Link to="/quotes">
                      <Card1>
                        <div className="Card1s">
                          {Description}
                          <div className="Descriptiondivtopbar">
                            {quotenotif1}
                          </div>
                        </div>
                        <div className="QuoteEnquiryReceived">
                          Quote Enquiry Received
                        </div>
                      </Card1>
                    </Link>

                    <Link to="/enquiry">
                      <Card1>
                        <div className="Card1s">
                          <FaUser />
                          <div className="Descriptiondivtopbar">
                            {enquirynotif2}
                          </div>
                        </div>
                        <div className="QuoteEnquiryReceived">
                          Enquiry Received
                        </div>
                      </Card1>
                    </Link>
                    <Link to="/careers">
                      <Card1>
                        <div className="Card1s">
                          <IoIosBriefcase />
                          <div className="Descriptiondivtopbar">
                            {careernotif3}
                          </div>
                        </div>
                        <div className="QuoteEnquiryReceived">
                          Career Application Received
                        </div>
                      </Card1>
                    </Link>
                  </NotificationBox>
                </TopBarIconContainer>
                <TopBarIconContainer>
                  <FiPower style={{ fontSize: 28 }} onClick={test} />
                </TopBarIconContainer>
              </RightContainer>
            </TopBarWraper>
          </TopBar>
        </div>
        {/* <!-- sticky bar end --> */}
      </div>
      {/* <!-- sticky bar parent end --> */}
    </>
  );
}
