import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Blog.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import urls from "../../../Urls";
import authHeader from "../../Login/auth-headers";

import { useForm } from "react-hook-form";
import JoditEditor from 'jodit-react';
const Blogs = () => {
  const editor = useRef(null);
  //validation
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const history = useHistory();
  const [blogtitle, setBlogtitle] = useState("");
  const [blogmessage, setBlogmessage] = useState("");
  const [content, setContent] = useState('');
  const [image, setImage] = React.useState("");
  const [errorsicon, setErroricon] = React.useState(false);
  const [errorsicons, setErroricons] = React.useState("");
  const [imageshow, setImageshow] = useState("");
  const [post, setPost] = useState("")
  console.log(post);
  const onchangeImage = (e) => {
    const blogimage = e.target.files[0];
    console.log("image", blogimage);
    setImageshow(URL.createObjectURL(e.target.files[0]));
    setImage(blogimage);
    document.getElementById("blogimageshow").style.display = "block";
    if (blogimage) {
      setErroricon("provide job icon");
      setErroricons(true);
    } else {
      setErroricon(false);
    }
  };
  const hanldePublic = () => {
    console.log(blogtitle, post, image);
    var formdata = new FormData();
    formdata.append("blog_title", blogtitle);
    formdata.append("blog_message", content);
    formdata.append("blog_images", image);
    console.log(formdata)
    fetch(urls + `/insertBlog`, {
      headers: authHeader(),
      method: "post",
      body: formdata,
    })
      .then((res) =>
        res.json().then((formdata) => {
          console.log("Response", formdata);
          toast.success("blog post sucsefully");
          setTimeout(() => {
            // window.location.reload();
            history.push(`/AllBlogs`);
          }, 2000);
        })
      )
      .catch((err) => {
        toast.error("something went wrong");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };

  return (
    <>
      <div className="maincontner">
        <div className="blogtopbar">
          <div className="Blogs">Blogs</div>

          <Link
            to="./AllBlogs"
            className="allBlogs"
            style={{ textDecoration: "none" }}
          >
            AllBlogs
          </Link>

          <button
            className="publish"
            onClick={handleSubmit(hanldePublic)}
            autoComplete="off"
            disabled={
              blogtitle === "" || content === "" || image === ""
                ? true
                : false
            }
          >
            Publish
          </button>
        </div>
        <div className="blogContener">
          <div className="blogcontents">
            <div className="BlogTitle">
              <label htmlFor="" className="blogstiteslas">
                Blog Title
              </label>
              <br />
              <input
                type="text"
                className="blogtitle"
                name="blog_title"
                {...register("username", { required: true })}
                required
                value={blogtitle}
                placeholder="Write your Title"
                onChange={(e) => {
                  setBlogtitle(e.target.value);
                  console.log(blogtitle);
                }}
              />
              <br />
              <span
                style={{
                  color: "red",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: "-10%",
                }}
              >
                {errors.blog_title?.type === "required" &&
                  "blog_title  is required"}
              </span>
            </div>

            <div>
              <label htmlFor="" className=" BlogDescription">
                Blog Description
              </label>
              <br />


              {/* <div className="editor">
                <CKEditor
                  editor={ClassicEditor}
                  placeholder="Write smathing ........"
                  // value={blogmessage}
                  required
                  data={blogmessage}
                  onChange={(e, editor) => {
                    const data = editor.getData();
                    setBlogmessage(data);
                    console.log(blogmessage);
                  }}
                />
                <br />
              </div> */}
              <JoditEditor
                ref={editor}
                value={content}
                onChange={newContent => setContent(newContent)}

              />


            </div>
            <div className="imageblog">
              <img
                style={{ display: "none" }}
                id="blogimageshow"
                src={imageshow}
                alt="i"
                className="imageblog "
              />
              <input
                onChange={onchangeImage}
                type="file"
                file={image}
                id="myFile"
                name="filename"
                required
              />
              <br />
              <span
                style={{
                  color: "red",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: "-10%",
                }}
              >
                {errors.filename?.filetype === "required" && "file is required"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Blogs;
