import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import axios from "axios";
// import "../AddBlog/Blog.css";

import authHeader from "../../Login/auth-headers";
import parser from "html-react-parser";
function SingleBlogshow() {
  const IMAGE_URL = `https://cerbosys.in:3700/blog/`;
  const [blogid, setBlogid] = useState("");
  const [getblogtitle, setgetBlogtitle] = useState("");
  const [getblogmessage, setgetBlogmessage] = useState("");
  const [image, setImage] = useState("");
  const history = useHistory();
  const { id } = useParams();
  console.log(id);

  useEffect(() => {
    axios
      .get(`https://cerbosys.in:3700/cerbosys/getBlogById?blog_id=${id}`, {
        headers: authHeader(),
      })
      .then((res) => {
        const i = res?.data?.data.length - 1;
        setBlogid(res?.data?.data[i].blog_id);

        setgetBlogtitle(res?.data?.data[i].blog_title);
        setgetBlogmessage(res?.data?.data[i].blog_message);
        setImage(res?.data?.data[i].blogImg[i].blog_images.substr(12));
        console.log(image);
      });
  }, [id]);
  return (
    <div className="mainContener">
      <div>
        <Link
          to={"/AllBlogs"}
          className="bi bi-arrow-left back "
          style={{ textDecoration: "none" }}
        >
          Back to Blog
        </Link>
      </div>
      <div className="sibglBlogLeftcontener">
        <div>
          <img src={IMAGE_URL + image} alt="" />
        </div>
        <div>{getblogtitle} </div>
        <div>{parser(getblogmessage)} </div>
      </div>
      <div className="sibglBlogRightcontener"></div>
    </div>
  );
}

export default SingleBlogshow;
