import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import axios from "axios";
import "../AddBlog/Blog.css";
import authHeader from "../../Login/auth-headers";
import JoditEditor from 'jodit-react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import urls from '../../../Urls';
function BlogEdit({ blog_title }) {
  const history = useHistory();
  const { id } = useParams();
  console.log(id);
  const [blogid, setBlogid] = useState("");
const editor = useRef(null);
  const [updateblogtitle, setupdateBlogtitle] = useState("");
    const [content, setContent] = useState('');
  const [updateblogmessage, setUpdateBlogmessage] = useState("");
  const [image, setImage] = useState("");
  const [blogimagesid, setBlogimagesid] = useState("");
  console.log(blogimagesid);
  const [errorsicon, setErroricon] = React.useState(false);
  const [errorsicons, setErroricons] = React.useState("");
  const [imageshow, setImageshow] = useState("");
  console.log(imageshow);
  const IMAGE_URL = `https://cerbosys.in:3700/blog/`;

  useEffect(() => {
    axios
      .get(`${urls}/getBlogById?blog_id=${id}`, {
      // .get(`https://cerbosys.in:3700/cerbosys/getBlogById?blog_id=${id}`, {
        headers: authHeader(),
      })
      .then((res) => {
        const i = res?.data?.data.length - 1;
        setBlogid(res?.data?.data[i].blog_id);
        setupdateBlogtitle(res?.data?.data[i].blog_title);
        setContent(res?.data?.data[i].blog_message);
        // setUpdateBlogmessage(res?.data?.data[i].blog_message);
        setBlogimagesid(res?.data?.data[i].blogImg[i].blog_imagesid);
        setImage(res?.data?.data[i].blogImg[i].blog_images.substr(12));
        console.log(res?.data?.data[i].blogImg[i].blog_imagesid);

        // const values = response.data.data;
        // setItem(() => {
        //   setItem(values);
        // });
        console.log(image);
      });
  }, [id]);
      const onchangeImage = (e) => {
        const blogimage = e.target.files[0];
        console.log("image", blogimage);
        setImageshow(URL.createObjectURL(e.target.files[0]));
        setImage(blogimage);
        document.getElementById("blogeditimageshow").style.display = "block";
        document.getElementById("blogeditGETimageshow").style.display = "none";
      };
  const hanldeUpdatePublic = () => {
    var formdata = new FormData();
    formdata.append("blog_id", blogid);
    formdata.append("blog_title", updateblogtitle);
    formdata.append("blog_message", content);
    formdata.append("blog_imagesid", blogimagesid);
    formdata.append("blog_images", image);

    fetch(` https://cerbosys.in:3700/cerbosys/updateBlog`, {
      headers: authHeader(),
      method: "post",
      body: formdata,
    })
      .then((res) =>
        res.json().then((formdata) => {
          console.log("Response", formdata);
          toast.success("blog update sucsefully");
          setTimeout(() => {
            history.push(`/AllBlogs`);
          }, 2000);
        })
      )
      .catch((err) => {
        toast.error("something went wrong");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  };

  return (
    <div>
      <div className="maincontner">
        <div className="blogtopbar">
          <div className="Blogs">EditBlogs</div>
          <div className="allBlogs">
            <Link
              to="/AllBlogs"
              className="allBlogs"
              style={{ textDecoration: "none" }}
            >
              AllBlogs
            </Link>
          </div>
          <button
            // type="submit"
            className="publish"
            onClick={hanldeUpdatePublic}
          >
            Update
          </button>
        </div>
        <div className="blogContener">
          <div className="blogcontents">
            <div className="blogappscontnet">
              <div className="BlogTitle">
                <label htmlFor=""> Update Blog Title</label> <br />
                <input
                  type="text"
                  className="blogtitle"
                  name="blog_title"
                  value={updateblogtitle}
                  placeholder="Write your Title"
                  onChange={(e) => {
                    setupdateBlogtitle(e.target.value);
                    console.log(updateblogtitle);
                  }}
                />
              </div>
              <div>
                <label htmlFor=""> Update Blog Description</label> <br />
                <div className="editor">
                  {/* <CKEditor
                    editor={ClassicEditor}
                    placeholder="Write smathing ........"
                    value={updateblogmessage}
                    data={updateblogmessage}
                    onChange={(e, editor) => {
                      const data = editor.getData();
                      setUpdateBlogmessage(data);
                      console.log(updateblogmessage);
                    }}
                  /> */}
 <JoditEditor
               ref={editor}
                                value={content}
onChange={newContent => setContent(newContent)}
                             
              />


                </div>
              </div>
              <div className="imageblog">
                <img
                  id="blogeditGETimageshow"
                  src={IMAGE_URL + image}
                  alt="."
                  className="imageblog "
                />
                <img
                  style={{ display: "none" }}
                  id="blogeditimageshow"
                  src={imageshow}
                  alt="."
                  className="imageblog "
                />
                <input onChange={onchangeImage} type="file" file={image} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default BlogEdit;
