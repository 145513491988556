// import All dependency to use this file
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
// import { useNavigate } from "react-router-dom";
import authHeader from '../Login/auth-headers';
import './dashboard.css';
import '../enquiry/enquirypopup.css';
import LoaderComp from '../Loader/LoaderComp';
import img1 from '../../images/briefecase.svg';
import img2 from '../../images/message.svg';
import img3 from '../../images/phonebook.svg';
import urls from '../../Urls';
import { useHistory } from 'react-router-dom';
import { IoCloseSharp } from 'react-icons/io5';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JoditEditor from 'jodit-react';
const Dashboard = () => {
  const editor = useRef(null);
  const history = useHistory();
  // All states for use in this file
  const [data, setData] = React.useState([]);
  // const [dashbordEn, setdashbordEn] = useState('');
  // console.log(dashbordEn);

  const [position, setPosition] = React.useState('');
  const [application, setApplication] = React.useState('');
  console.log(application);

  const [location, setLocation] = React.useState('');
  const [type, setType] = React.useState('');
  const [experience, setExperience] = React.useState('');
  const [salary, setSalary] = React.useState(0);
  const [salary2, setSalary2] = React.useState(0);
  const [desc, setDesc] = useState('');
  const [jobicons, setJobIcon] = React.useState('');
  const [TotalCurrentOpenings, setTotalCurrentOpenings] = useState('');
  console.log('TotalCurrentOpenings', TotalCurrentOpenings);
  const [job, setJob] = useState([]);
  const [TotalEnqury, setTotalEnqury] = useState('');
  console.log('TotalEnqury', TotalEnqury);
  const [errorspos, setErrorspos] = React.useState(false);
  const [errorsloc, setErrorsloc] = React.useState(false);
  const [errorsty, setErrorsty] = React.useState(false);
  const [errorsex, setErrorsex] = React.useState(false);
  const [errorssal, setErrorssal] = React.useState(false);
  const [errorsdes, setErrorsdes] = React.useState(false);
  const [errorsicon, setErroricon] = React.useState(false);

  const [errorsposition, setErrorsposition] = React.useState('');
  const [errorslocation, setErrorslocation] = React.useState('');
  const [errorssalary, setErrorssalary] = React.useState('');
  const [errorsdesc, setErrorsdesc] = React.useState('');
  const [errorsexp, setErrorsexp] = React.useState('');
  const [errorstype, setErrorstype] = React.useState('');
  const [errorsicons, setErroricons] = React.useState('');

  const [enquir, setEnquir] = useState('');
  const [name, setName] = useState('');

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [enquiryservice, setenquiryservice] = useState('');
  const [hearaboutus, setHearaboutus] = useState('');

  const [message, setMessage] = useState('');
  const [enquirystatus, setEnquirystatus] = useState('');
  // const navigation = useNavigate();
  let userToken = localStorage.getItem('usertoken');
  const [isloading, setisloading] = useState(undefined);
  const [content, setContent] = useState('');
// console.log(content);
  useEffect(() => {
    // this is for getAllEnqiury,getTotalEnquiry for dashbord card and table in to of

    axios.get(urls + '/todayEnquiry').then((res) => {
      setData(res.data.data);
      setisloading(true);
    });
  }, []);
  // useEffect(() => {
  //   axios
  //     .get(`${urls}/getTotalEnquiry`, {
  //       headers: authHeader(),
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       setTotalEnqury({ enquiry: res.data });
  //     });

  //   // this is for TotalCurrentOpenings for dashbord card in to of

  //   axios
  //     .get(`${urls}/getTotalCurrentOpenings`, { headers: authHeader() })
  //     .then((res) => {
  //       console.log(res);
  //       setTotalCurrentOpenings({
  //         TotalCurrentOpenings: res.data,
  //       });
  //     });

  //   // this is for getTotalCareerApplication for dashbord card in to of

  //   axios
  //     .get(`${urls}/getTotalCareerApplication`, { headers: authHeader() })
  //     .then((res) => {
  //       setApplication({ CareerAplication: res });
  //       console.log(res);
  //     });
  // }, []);

  // get total api in card
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    // get total prececreption
    fetch(`${urls}/getTotalCareerApplication`, {
      headers: authHeader(),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.success) {
          setApplication({ CareerAplication: data.data[0].Total });
        }
      });

    // get total appointment
    fetch(`${urls}/getTotalCurrentOpenings`, {
      headers: authHeader(),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.success) {
          setTotalCurrentOpenings({ TotalCurrentOpenings: data.data[0].Total });
        }
      });

    // get total free driver
    fetch(`${urls}/getTotalEnquiry`, {
      headers: authHeader(),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.success) {
          console.log(data);
          setTotalEnqury({ enquiry: data.data[0].Total });
        }
      });
  }, []);

  // Job post section for tak value and  and set value in state  with validation
  const onchangePosition = (e) => {
    const position = e.target.value.replace(/[^a-z]/gi, ' ');
    setPosition(position);
    if (position.length <= 3) {
      setErrorsposition('Enter Position');
      setErrorspos(true);
    } else {
      setErrorspos(false);
    }
  };

  const onchangeLocation = (e) => {
    const location = e.target.value.replace(/[^a-z]/gi, ' ');
    setLocation(location);
    if (location.length <= 3) {
      setErrorslocation('Enter location');
      setErrorsloc(true);
    } else {
      setErrorsloc(false);
    }
  };
  const onchangeExperience = (e) => {
    const experience = e.target.value;
    setExperience(experience);
    if (experience.value <= 0) {
      setErrorsexp('Enter  Experience');
      setErrorsex(true);
    } else {
      setErrorsex(false);
    }
  };
  const onchangeIcons = (e) => {
    const icons = e.target.files[0];
    console.log('jobIcon', icons);
    setJobIcon(icons);
    if (icons) {
      setErroricon('provide job icon');
      setErroricons(true);
    } else {
      setErroricon(false);
    }
  };
  const onchangeDesc = (e) => {
    const desc = e.target.value;
    setDesc(desc);
    if (desc.length < 10) {
      setErrorsdesc('Enter minimum 10 word in Description');
      setErrorsdes(true);
    } else {
      setErrorsdes(false);
    }
  };
  const onchangeType = (e) => {
    const type = e.target.value;
    setType(type);
    if (type.length <= 3) {
      setErrorstype('Enter Type');
      setErrorsty(true);
    } else {
      setErrorsty(false);
    }
  };
  const onchangeSalary = (e) => {
    const salary = e.target.value;
    setSalary(salary);
    if (salary.length <= 6) {
      setErrorssalary('Enter salary');
      setErrorssal(true);
    } else {
      setErrorssal(false);
    }
  };

  //  Job post data with help of  post api coling
  const jobPost = () => {
    var formdata = new FormData();
    formdata.append('job_icon', jobicons);
    formdata.append('jobposition', position);
    formdata.append('joblocation', location);
    formdata.append('jobexperience', experience);
    formdata.append('jobemploymenttype', type);
    formdata.append('salaryscale', salary);
    formdata.append('jobdescription', content);
    console.log(jobicons, position, location, experience, type, salary, content);
    fetch(urls + '/insertJobPost', {
      headers: authHeader(),
      method: 'post',
      body: formdata,
    })
      .then((res) =>
        res.json().then((formdata) => {
          console.log(res);
          console.log('Response', formdata);
          // alert("job Succefully Add");
          toast.success('job Succefully Add');
          setTimeout(() => {
            history.push(`/careers`);
          }, 1000);
        })
      )
      .catch((err) => {
        console.log('error', err);
        toast.error('job not post');
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };

  // --------------------- for update data -------
  // get by id

  const hanldeClick = async (enquiry_id) => {
    axios
      .get(`${urls}//getEnquiryById?enquiry_id=${enquiry_id}`, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(enquiry_id);
        if (res.data.data) {
          const i = res?.data?.data.length - 1;
          localStorage.getItem(userToken);
          setEnquir(res?.data?.data[i].enquiry_id);
          setName(res?.data?.data[i].username);
          setEmail(res?.data?.data[i].email);
          setPhone(res?.data?.data[i].contact_number);
          setSubject(res?.data?.data[i].subject);
          setenquiryservice(res?.data?.data[i].service_name);
          setHearaboutus(res?.data?.data[i].hearabout_us);
          setMessage(res?.data?.data[i].message);
          setEnquirystatus(res?.data?.data[i].enquirystatus);
        }
        document.getElementById('enquiry_popup').style.display = 'block';
        console.log(res.data.data);
      });
  };

  // update Enquiry function
  const updateEnquiryStatus = async (e) => {
    e.preventDefault();
    axios
      .post(
        `https://cerbosys.in:3700/cerbosys/updateEnquiryStatus`,
        {
          enquiry_id: enquir,
          enquiry_status: enquirystatus,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        setEnquirystatus('');
        console.log('update enquirystatus data', res);
        document.getElementById('enquiry_popup').style.display = 'none';
        toast.success('update Enquirystatus');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        toast.error('data not seved');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  };

  // close popup model
  const modal = () => {
    document.getElementById('enquiry_popup').style.display = 'none';
  };

  return (
    <>
      <div className="dashbordContener">
        <div className="dashbordleftContener">
          <div className="dashbord-box ">
            <Link to="/careers" style={{ color: '#FFFFFF' }}>
              <div className="dashbordBoxIner ">
                <div>
                  <img
                    src={img1}
                    className="dashbord-iamge"
                    // style={{ width: "40%" }}
                    alt="icon"
                  />
                </div>
                <div>
                  <i classname="fa-solid fa-ellipsis-vertical"></i>
                </div>
                <div className="box--text">Current Openings</div>
                <div className="box--number">
                  {TotalCurrentOpenings.TotalCurrentOpenings}
                </div>
              </div>
            </Link>

            <Link to="/careers" style={{ color: '#FFFFFF' }}>
              <div className="dashbordBoxIner">
                <div>
                  <img
                    src={img3}
                    className="dashbord-iamge"
                    // style={{ width: "40%" }}
                    alt="icon"
                  />
                </div>
                <div className="box--text">Career Applications</div>
                <div className="box--number">
                  {application.CareerAplication}
                </div>
              </div>
            </Link>

            <Link to="/enquiry" style={{ color: '#FFFFFF' }}>
              <div className="dashbordBoxIner">
                <div>
                  <img
                    src={img2}
                    className="dashbord-iamge"
                    // style={{ width: "40%" }}
                    alt="icon"
                  />{' '}
                </div>
                <div className="box--text">Total Enquiry</div>
                <div className="box--number">{TotalEnqury.enquiry}</div>
              </div>
            </Link>
          </div>

          <div>
            {!isloading ? (
              <LoaderComp />
            ) : (
              <table className="table">
                <tr>
                  <th className="firsttbalheder">Sr.no</th>
                  <th>Name </th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Service</th>
                  <th>Refrence</th>
                  <th className="lasttbalheder">Status</th>
                </tr>

                <tbody>
                  {data ? (
                    data.map((enq, index) => (
                      <tr
                        key={index}
                        onClick={() => hanldeClick(enq.enquiry_id)}
                        id={enq.fenquiry_id}
                      >
                        <td className="tdfirsttabl">{index + 1}</td>
                        <td>{enq.username}</td>
                        <td>{enq.email}</td>
                        <td>{enq.contact_number}</td>
                        <td>{enq.service_name}</td>
                        <td>{enq.hearabout_us}</td>
                        <td>{enq.enquiry_status}</td>
                      </tr>
                    ))
                  ) : (
                    <div className="container">No data</div>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div className="dashbordrightContener   ">
          <div className="Box2">
            <div className="job-title">Job Post</div>
            <div className="jobs">
              <div className="field">
                <label className="label" style={{ color: '#000000' }}>
                  Position
                </label>
                <div className="control">
                  <input
                    className="input is-normal  control form-control "
                    type="text"
                    value={position}
                    onChange={onchangePosition}
                    // type="text"
                  />
                </div>
                {errorspos && (
                  <small style={{ color: 'red', fontSize: 15 }}>
                    {errorsposition}
                  </small>
                )}
              </div>

              <div className="field">
                <label className="label" style={{ color: '#000000' }}>
                  Location
                </label>
                <div className="control">
                  <input
                    className="input is-normal control form-control "
                    type="text"
                    value={location}
                    onChange={onchangeLocation}
                  />
                </div>
                {errorsloc && (
                  <small style={{ color: 'red', fontSize: 15 }}>
                    {errorslocation}
                  </small>
                )}
              </div>

              <div className="field">
                <label className="label" style={{ color: '#000000' }}>
                  Experience
                </label>
                <div className="control form-control-Experience ">
                  <input
                    className="form-control"
                    // color="blue"
                    min="0"
                    max="30"
                    step="1"
                    defaultValue="0"
                    type="range"
                    value={experience}
                    onChange={onchangeExperience}
                  />
                  <span>
                    <output for="experience" className="exp-yer">
                      {experience} Years
                    </output>
                  </span>
                </div>
                {errorsex && (
                  <small style={{ color: 'red', fontSize: 15 }}>
                    {errorsexp}
                  </small>
                )}
              </div>

              <div className="field">
                <label className="label" style={{ color: '#000000' }}>
                  Employment Type
                </label>
                <div className="" style={{ borderRadius: 10 }}>
                  <div className="select is-fullwidth ">
                    <select
                      className="input is-normal control form-control"
                      value={type}
                      onChange={onchangeType}
                    >
                      <option value="">Select type</option>
                      <option value="Full Time">Full Time</option>
                      <option value="Part Time">Part Time</option>
                      <option value="Freelancer">Freelancer</option>
                    </select>
                  </div>
                </div>
                {errorsty && (
                  <small style={{ color: 'red', fontSize: 15 }}>
                    {errorstype}
                  </small>
                )}
              </div>
              <div className="field">
                <label className="label" style={{ color: '#000000' }}>
                  Salary Scale
                </label>
                <div className="" style={{ borderRadius: 10 }}>
                  <div className="select is-fullwidth ">
                    <select
                      className="input is-normal control form-control"
                      value={salary}
                      onChange={onchangeSalary}
                    >
                      <option value="">Select type</option>
                      <option value="10000-30000">10000-30000 </option>
                      <option value="30000-60000">30000-60000 </option>
                      <option value="60000-100000">60000-100000 </option>
                      {/* <option value="10-15">10-15 </option>
                      <option value="15-20">15-20 </option>
                      <option value="20-25">20-25 </option> */}
                    </select>
                  </div>
                </div>
                {errorsty && (
                  <small style={{ color: 'red', fontSize: 15 }}>
                    {errorstype}
                  </small>
                )}
              </div>
              {/* 
              <div className="field">
                <label className="label" style={{ color: "#000000" }}>
                  Salary Scale
                </label>
                <div className=" ">
                  <input
                    className="input is-normal control form-control "
                    type="text"
                    value={salary}
                    onChange={onchangeSalary}
                  />
                  
                  &nbsp;To&nbsp;
                  
                  <input
                    className="input is-normal control form-control "
                    type="text"
                    value={salary2}
                    // onChange={onchangeSalarymax}
                  />
                </div>
                {errorssal && (
                  <small style={{ color: "red", fontSize: 15 }}>
                    {errorssalary}
                  </small>
                )}
              </div> */}

              <div className="field">
                <label className="label" style={{ color: '#000000' }}>
                  Tech Icon
                </label>
                <div className="">
                  <input
                    className="file-upload input is-normal control form-control-file  "
                    onChange={onchangeIcons}
                    type="file"
                    file={jobicons}
                    id="myFile"
                    name="filename"
                  />
                </div>
                {errorsicon && (
                  <small style={{ color: 'red', fontSize: 15 }}>
                    {errorsicons}
                  </small>
                )}
              </div>

              <div className="field">
                <label className="label" style={{ color: '#000000' }}>
                  Job Description
                </label>
                {/* <div className="">
                  <textarea
                    s="5"
                    className="textarea input is-normal control form-control"
                    value={desc}
                    onChange={onchangeDesc}
                    style={{
                      minHeight: '50px',
                      maxHeight: '200px',
                      maxWidth: '90%',
                    }}
                  ></textarea>
                </div> */}
                <JoditEditor
                   ref={editor}
                                value={content}
onChange={newContent => setContent(newContent)}         
              />
                {errorsdes && (
                  <small style={{ color: 'red', fontSize: 15 }}>
                    {errorsdesc}
                  </small>
                )}
              </div>
              <div
                className="form-control-buuthon "
                style={{ textAlign: 'center' }}
              >
                <button
                  className="dashbordsubmitbutton"
                  onClick={jobPost}
                  disabled={
                    position === '' ||
                    location === '' ||
                    salary2 === '' ||
                    type === '' ||
                    experience === '' ||
                    content === ''
                      ? true
                      : false
                  }
                >
                  Post this job
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Popupbox Start */}
        <div id="enquiry_popup" style={{ display: 'none' }}>
          <div className=" enquiryPopup">
            <div className=" conteners ">
              <div className="" onClick={modal}>
                <Link to="/">
                  <IoCloseSharp className="IoCloseSharp" />
                </Link>
              </div>
              <div className="flexcontener">
                <div className=" maincontent">
                  <div className="enquirypoptitle">Enquiry - {enquir}</div>
                  <hr />
                  <div className="popupboxleft">
                    Name
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{name}</span>
                  </div>

                  <div className="popupboxleft">
                    Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{email}</span>
                  </div>
                  <div className="popupboxleft">
                    Phone No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{phone}</span>
                  </div>
                  <div className="popupboxleft">
                    Subject&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{subject}</span>
                  </div>
                  <div className="popupboxleft">
                    Service&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{enquiryservice}</span>
                  </div>
                  <div className="popupboxleft">
                    Heardabout&nbsp;&nbsp; -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{hearaboutus}</span>
                  </div>
                  <div className="popupboxleft">
                    Message&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{message}</span>
                  </div>
                </div>
                <form
                  onSubmit={(e) => updateEnquiryStatus(e)}
                  autoComplete="off"
                >
                  <div className="buttom-flex">
                    <div>
                      <input
                        className="is-checkradio is-rtl"
                        onChange={(e) => {
                          setEnquirystatus(e.target.value);
                          console.log(enquirystatus);
                        }}
                        value="acknowledgement"
                        id="exampleRtlRadioInline1"
                        type="radio"
                        name="exampleRtlRadioInline"
                      />
                      <label
                        for="exampleRtlRadioInline1"
                        style={{ margin: 20 }}
                      >
                        acknowledgement
                      </label>
                    </div>
                    <div>
                      <input
                        className="is-checkradio is-rtl"
                        id="exampleRtlRadioInline2"
                        onChange={(e) => setEnquirystatus(e.target.value)}
                        value="working"
                        type="radio"
                        name="exampleRtlRadioInline"
                      />
                      <label
                        for="exampleRtlRadioInline2"
                        style={{ margin: 20 }}
                      >
                        Working
                      </label>
                    </div>
                    <div>
                      <input
                        className="is-checkradio is-rtl"
                        id="exampleRtlRadioInline3"
                        type="radio"
                        onChange={(e) => setEnquirystatus(e.target.value)}
                        value="completed"
                        name="exampleRtlRadioInline"
                      />
                      <label
                        for="exampleRtlRadioInline3"
                        style={{ margin: 20 }}
                      >
                        Completed
                      </label>
                    </div>

                    <div>
                      <input
                        className="is-checkradio is-rtl"
                        id="exampleRtlRadioInline4"
                        type="radio"
                        onChange={(e) => setEnquirystatus(e.target.value)}
                        value="open"
                        name="exampleRtlRadioInline"
                      />
                      <label
                        for="exampleRtlRadioInline4"
                        style={{ margin: 20 }}
                      >
                        Open
                      </label>
                    </div>
                  </div>
                  <div>
                    <button
                      className="submintbutton is-pulled-right"
                      // onClick={() => updateStatus(enquir.enquiry_id)}
                      style={{}}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* popupbox End  */}
      </div>
      <div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Dashboard;
