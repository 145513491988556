import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoaderComp from '../Loader/LoaderComp';
import { Link, useHistory } from 'react-router-dom';
import { IoCloseSharp } from 'react-icons/io5';
import { ToastContainer, toast } from 'react-toastify';
import urls from '../../Urls';
import authHeader from '../Login/auth-headers';
const CaseStudy = () => {
  const [isloading, setisloading] = useState(undefined);
  const [service, setService] = React.useState([]);
  const GetAllService = () => {
    axios
      .get(urls + '/getAllSubscription', {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res);
        setService(res.data.data);
        setisloading(true);
      });
  };
  useEffect(() => {
    GetAllService();
  }, []);

  return (
    <>
      <div className="ServicesTopbar">
        <div className="Services1">
          <h3>Subscription</h3>
        </div>
        <div></div>
        <div></div>
      </div>
      <div className="serviceFlexBox">
        <div className="ServiceLeftcontener">
          {!isloading ? (
            <LoaderComp />
          ) : (
            <table className="table">
              <tr>
                <th className="firsttbalheder">Sr.no</th>
                <th className="lasttbalheder">CaseStudy Subscription Email</th>
              </tr>

              <tbody>
                {service ? (
                  service.map((Ser, index) => (
                    <tr>
                      <td className="tdfirsttabl">{index + 1}</td>
                      <td>{Ser.subscription_email}</td>
                    </tr>
                  ))
                ) : (
                  <div className="container">No Data</div>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};
export default CaseStudy;
