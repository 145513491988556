import React from "react";
import "./NotFound.css";

import { Link } from "react-router-dom";

function Notfound() {
  return (
    <div className="outerNotfound">
      <div className="NotFound404">
        <h1>404</h1>
        <h1> Sorry !! Page Not Found </h1>
        <Link style={{ textDecoration: "none" }} to={"/"}>
          <div className="notfoundButthon">Back HomePage</div>
        </Link>
      </div>
    </div>
  );
}

export default Notfound;
