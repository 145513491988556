import React, { useState } from 'react';
import axios from 'axios';
import urls from '../../Urls';
import LoaderComp from '../Loader/LoaderComp';
import authHeader from '../Login/auth-headers';
import { Link } from 'react-router-dom';
import { IoCloseSharp } from 'react-icons/io5';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GrDocumentDownload } from 'react-icons/gr';
import jsPDF from 'jspdf';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import CerboLogo from '../../images/logo.png';

function Investment() {
  const [data, setData] = React.useState([]);
  const [isloading, setisloading] = useState(undefined);
  const [Invest, setInvest] = useState('');
  const [investmentenquiryname, setinvestmentenquiryname] = useState('');
  const [investmentenquirycompanyname, setinvestmentenquirycompanyname] =
    useState('');
  const [email, setEmail] = useState('');
  const [contactnumber, setcontactnumber] = useState('');
  const [investmentamount, setinvestmentamount] = useState('');
  const [InvestEnquirystatus, setInvestEnquirystatus] = useState('');
  // get all data in table
  React.useEffect(() => {
    axios
      .get(urls + '/getAllInvestmentEnquiry', {
        headers: authHeader(),
      })

      .then((res) => {
        setData(res.data.data);
        console.log(res.data);
        setisloading(true);
      });
  }, []);

  // get by id

  const hanldeClick = async (investment_enquiryid) => {
    axios
      .get(
        `${urls}//getInvestmentEnquiryById?investment_enquiryid=${investment_enquiryid}`,
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        console.log(investment_enquiryid);
        console.log(res);
        if (res.data.data) {
          const i = res?.data?.data.length - 1;
          setInvest(res?.data?.data[i].investment_enquiryid);
          setinvestmentenquiryname(res?.data?.data[i].investment_enquiryname);
          setinvestmentenquirycompanyname(
            res?.data?.data[i].investment_enquirycompanyname
          );
          setEmail(res?.data?.data[i].email);
          setcontactnumber(res?.data?.data[i].contact_number);
          setinvestmentamount(res?.data?.data[i].investment_amount);
          setInvestEnquirystatus(res?.data?.data[i].investment_status);
        }
        document.getElementById('invest_popup').style.display = 'block';
        console.log(res.data.data);
      });
  };
  // update Enquiry function

  const updateEnquiryStatus = async (e) => {
    e.preventDefault();
    axios
      .patch(
        `${urls}/updateInvestmentEnquiryStatus`,
        {
          investment_enquiryid: Invest,
          investment_status: InvestEnquirystatus,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        console.log('update investstatus data', res);
        // alert("update enquirystatus data");
        document.getElementById('invest_popup').style.display = 'none';
        // window.location.reload();
        toast.success('update enquiry status');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        toast.error(' status Not update');
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };

  // close popup model
  const modal = () => {
    document.getElementById('invest_popup').style.display = 'none';
  };

  // dawnlod pdf start
  const pdfGenreter = (invest) => {
    const doc = new jsPDF();
    console.log(invest);
    // doc.addImage({item.})
    // doc.setFontSize(22);
    doc.addImage(CerboLogo, 'PNG', 50, 50, 50, 30);
    // doc.setTextColor(255, 56, 6);
    // doc.text(40, 20, 'Cerbosys ');
    // doc.text(40, 40, 'Enquiry');

    doc.setFont('poppins ', 'bold');
    doc.text('Name', 50, 100);
    doc.text('Email.', 50, 110);
    doc.text('Phone No', 50, 120);
    doc.text('Company', 50, 130);
    doc.text('Amount', 50, 140);
    doc.text('Status', 50, 150);
    doc.setFont('poppins ', 'Normal');
    doc.text(`${invest.investment_enquiryname}`, 80, 100);
    doc.text(`${invest.email}`, 80, 110);
    doc.text(`${invest.contact_number}`, 80, 120);
    doc.text(`${invest.investment_enquirycompanyname}`, 80, 130);
    doc.text(`${invest.investment_amount}`, 80, 140);
    doc.text(`${invest.investment_status}`, 80, 150);
    doc.save(`Investment_${invest.investment_enquiryname}.pdf`);
  };
  // dawnlod pdf End

  return (
    <>
      <div>
        <div className="enqurt_topFlex">
          <div className="EnquiryTitle">
            <h1 className="Enqtitle">Investment</h1>
          </div>
          <div></div>
          <div>
            {/* <select className="Enquiryselect"  onChange={select}>
            <option value="1">All</option>
            <option value="2">Open</option>
            <option value="3">Acknowledged</option>
            <option value="4">Working</option>
            <option value="5">Completed</option>
          </select> */}
            <div className="">
              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="expots"
                table="table-to-xls"
                filename="Investment"
                sheet="Investment"
                buttonText="as XLS"
              />
            </div>
          </div>
        </div>
        <br></br>
        {!isloading ? (
          <LoaderComp />
        ) : (
          <table className="table" id="table-to-xls">
            <thead>
              <tr>
                <th className="firsttbalheder">Sr.no</th>
                <th>Name</th>
                <th>Company </th>
                <th>Amount</th>
                <th>Mobile </th>
                <th>Email</th>
                <th>Status</th>
                <th className="lasttbalheder">Download</th>
              </tr>
            </thead>
            <tbody>
              {data ? (
                data.map((invest, index) => (
                  <tr
                    style={{ textDecoration: 'none' }}
                    onClick={() => hanldeClick(invest.investment_enquiryid)}
                    id={invest.investment_enquiryid}
                  >
                    <td className="tdfirsttabl">{index + 1}</td>
                    <td>{invest.investment_enquiryname}</td>
                    <td>{invest.investment_enquirycompanyname}</td>
                    <td>{invest.investment_amount}</td>
                    <td>{invest.contact_number}</td>
                    <td>{invest.email}</td>
                    <td>{invest.investment_status}</td>
                    <td onClick={() => pdfGenreter(invest)}>
                      <GrDocumentDownload />
                    </td>
                  </tr>
                ))
              ) : (
                <div className="enquirynodata">No Detail Available Invest</div>
              )}
            </tbody>
          </table>
        )}
      </div>
      <div>
        <ToastContainer />
      </div>
      {/* Invest modal oppen  */}
      <div id="invest_popup" style={{ display: 'none' }}>
        <div className="enquiryPopup">
          <div className="conteners ">
            <div className="" onClick={modal}>
              <Link to="/investment">
                <IoCloseSharp className="IoCloseSharp" />
              </Link>
            </div>
            <div className="flexcontener">
              <div className=" maincontent">
                <div className="enquirypoptitle">investment - {Invest}</div>
                <hr />
                <div className="popupboxleft">
                  Name
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="popupboxright">{investmentenquiryname}</span>
                </div>
                <div className="popupboxleft">
                  Companyname
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="popupboxright">
                    {investmentenquirycompanyname}
                  </span>
                </div>

                <div className="popupboxleft">
                  Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="popupboxright">{email}</span>
                </div>

                <div className="popupboxleft">
                  Phone No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="popupboxright">{contactnumber}</span>
                </div>
                {/* ----------------------------- */}
                <div className="popupboxleft">
                  Investment
                  amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="popupboxright">{investmentamount}</span>
                </div>
              </div>
              <div class="col-75">
        <textarea id="subject" name="subject" placeholder="Write Nots about this "
         style={{height:'100px'}}></textarea>
      </div>
              <form onSubmit={(e) => updateEnquiryStatus(e)} autoComplete="off">
                <div className="buttom-flex">
                  <div>
                    <input
                      className="is-checkradio is-rtl"
                      onChange={(e) => {
                        setInvestEnquirystatus(e.target.value);
                      }}
                      value="acknowledgement"
                      id="exampleRtlRadioInline1"
                      type="radio"
                      name="exampleRtlRadioInline"
                    />
                    <label for="exampleRtlRadioInline1" style={{ margin: 20 }}>
                      acknowledgement
                    </label>
                  </div>
                  <div>
                    <input
                      className="is-checkradio is-rtl"
                      id="exampleRtlRadioInline2"
                      onChange={(e) => {
                        setInvestEnquirystatus(e.target.value);
                      }}
                      value="working"
                      type="radio"
                      name="exampleRtlRadioInline"
                    />
                    <label for="exampleRtlRadioInline2" style={{ margin: 20 }}>
                      Working
                    </label>
                  </div>
                  <div>
                    <input
                      className="is-checkradio is-rtl"
                      id="exampleRtlRadioInline3"
                      type="radio"
                      onChange={(e) => {
                        setInvestEnquirystatus(e.target.value);
                      }}
                      value="completed"
                      name="exampleRtlRadioInline"
                    />
                    <label for="exampleRtlRadioInline3" style={{ margin: 20 }}>
                      Completed
                    </label>
                  </div>

                  <div>
                    <input
                      className="is-checkradio is-rtl"
                      id="exampleRtlRadioInline4"
                      type="radio"
                      onChange={(e) => {
                        setInvestEnquirystatus(e.target.value);
                      }}
                      value="open"
                      name="exampleRtlRadioInline"
                    />
                    <label for="exampleRtlRadioInline4" style={{ margin: 20 }}>
                      Open
                    </label>
                  </div>
                </div>
                <div>
                  <button
                    className="submintbutton is-pulled-right"
                    // onClick={() => updateStatus(enquir.enquiry_id)}
                    style={{}}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Investment;
