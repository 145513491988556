import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import urls from "../../Urls";
import authHeader from "../Login/auth-headers";
import "./enquiry.css";
import "../dashboard/dashboard.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoaderComp from "../Loader/LoaderComp";
import "./enquirypopup.css";
import { IoCloseSharp } from "react-icons/io5";
import { GrDocumentDownload } from "react-icons/gr";
import jsPDF from "jspdf";
import CerboLogo from "../../images/logo.png";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
const Enquiry = () => {
  // states
  const [data, setData] = React.useState([]);
  const [isloading, setisloading] = useState(undefined);
  // this state for use popbox get by id
  const [enquir, setEnquir] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [enquiryservice, setenquiryservice] = useState("");
  const [hearaboutus, setHearaboutus] = useState("");
  const [message, setMessage] = useState("");
  const [organization, setOrganization] = useState("");
  const [enquirystatus, setEnquirystatus] = useState("");
  let userToken = localStorage.getItem("usertoken");

  // get all data in table

  React.useEffect(() => {
    axios.get(urls + "/getAllEnquiry").then((res) => {
      setData(res.data.data);
      setisloading(true);
    });
  }, []);

  // select search Function
  function select(event) {
    // console.log(event);
    if (event.target.value === "1") {
      // alert("1")
      fetch(`${urls}/getAllEnquiry`, {
        headers: authHeader(),
      })
        .then((res) => res.json())
        .then((res) => {
          setData(res.data);
          console.log("Enquiry", res.data);
        });
    } else if (event.target.value === "2") {
      // alert("2")
      fetch(`${urls}/filterEnquiry?enquiry_status=open`, {
        headers: authHeader(),
      })
        .then((res) => res.json())
        .then((res) => {
          setData(res.data);
          // alert(res.message);

          console.log("open", res);
        });
    } else if (event.target.value === "3") {
      // alert("3")
      fetch(`${urls}/filterEnquiry?enquiry_status=aknowledgement`, {
        headers: authHeader(),
      })
        .then((res) => res.json())
        .then((res) => {
          setData(res.data);
          // alert(res.message);

          console.log("acknowledged", res);
        });
    } else if (event.target.value === "4") {
      // alert("4");
      fetch(`${urls}/filterEnquiry?enquiry_status=working`, {
        headers: authHeader(),
      })
        .then((res) => res.json())
        .then((res) => {
          setData(res.data);
          // alert(res.message);
          console.log("working", res);
        });
    } else {
      // alert("5")
      fetch(`${urls}/filterEnquiry?enquiry_status=completed`, {
        headers: authHeader(),
      })
        .then((res) => res.json())
        .then((res) => {
          setData(res.data);
          // alert(res.message);

          console.log("completed", res);
        });
    }
  }

  // get by id

  const hanldeClick = async (enquiry_id) => {
    axios
      .get(`${urls}//getEnquiryById?enquiry_id=${enquiry_id}`, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(enquiry_id);
        if (res.data.data) {
          const i = res?.data?.data.length - 1;
          localStorage.getItem(userToken);
          setEnquir(res?.data?.data[i].enquiry_id);
          setName(res?.data?.data[i].username);
          setEmail(res?.data?.data[i].email);
          setPhone(res?.data?.data[i].contact_number);
          setSubject(res?.data?.data[i].subject);
          setenquiryservice(res?.data?.data[i].service_name);
          setHearaboutus(res?.data?.data[i].hearabout_us);
          setOrganization(res?.data?.data[i].organization_name);
          setMessage(res?.data?.data[i].message);
          setEnquirystatus(res?.data?.data[i].enquirystatus);
        }
        document.getElementById("enquiry_popup").style.display = "block";
        console.log(res.data.data);
      });
  };

  // update Enquiry function

  const updateEnquiryStatus = async (e) => {
    e.preventDefault();
    axios
      .post(
        `https://cerbosys.in:3700/cerbosys/updateEnquiryStatus`,
        {
          enquiry_id: enquir,
          enquiry_status: enquirystatus,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        setEnquirystatus("");
        console.log("update enquirystatus data", res);
        // alert("update enquirystatus data");
        document.getElementById("enquiry_popup").style.display = "none";
        // window.location.reload();
        toast.success("update enquiry status");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        toast.error(" status Not update");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };

  // close popup model
  const modal = () => {
    document.getElementById("enquiry_popup").style.display = "none";
  };

  // dawnlod pdf start
  const pdfGenreter = (enq) => {
    const doc = new jsPDF();
    console.log(enq);
    // doc.addImage({item.})
    // doc.setFontSize(22);
    doc.addImage(CerboLogo, "PNG", 50, 50, 50, 30);
    // doc.setTextColor(255, 56, 6);
    // doc.text(40, 20, 'Cerbosys ');
    // doc.text(40, 40, 'Enquiry');

    doc.setFont("poppins ", "bold");
    doc.text("Name", 50, 100);
    doc.text("Email.", 50, 110);
    doc.text("Phone No", 50, 120);
    doc.text("Service", 50, 130);
    doc.text("hearabout", 50, 140);
    doc.text("Status", 50, 150);
    doc.setFont("poppins ", "Normal");
    doc.text(`${enq.username}`, 80, 100);
    doc.text(`${enq.email}`, 80, 110);
    doc.text(`${enq.contact_number}`, 80, 120);
    doc.text(`${enq.service_name}`, 80, 130);
    doc.text(`${enq.hearabout_us}`, 80, 140);
    doc.text(`${enq.enquiry_status}`, 80, 150);
    doc.save(`Enquiry_${enq.username}.pdf`);
  };
  // dawnlod pdf End

  return (
    <>
      <div className="">
        <div className="enqurt_topFlex">
          <div className="EnquiryTitle">
            <h1 className="Enqtitle">Enquiry</h1>
          </div>
          <div></div>
          <div>
            <select className="Enquiryselect" onChange={select}>
              <option value="1">All</option>
              <option value="2">Open</option>
              <option value="3">Acknowledged</option>
              <option value="4">Working</option>
              <option value="5">Completed</option>
            </select>
          </div>
          <div className="EnquiryTitle">
            <h1>
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="expots"
                table="table-to-xls"
                filename="Enquiry"
                sheet="Enquiry"
                buttonText="as XLS"
              />
            </h1>
          </div>
        </div>
        <br></br>

        {!isloading ? (
          <LoaderComp />
        ) : (
          <table className="table" id="table-to-xls">
            <thead>
              <tr>
                <th className="firsttbalheder">Sr.no</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile </th>

                <th>Service</th>
                <th>Refrence</th>
                <th>Status</th>
                <th className="lasttbalheder">Download</th>
              </tr>
            </thead>

            <tbody>
              {data ? (
                data.map((enq, index) => (
                  <tr
                    key={index}
                    onClick={() => hanldeClick(enq.enquiry_id)}
                    id={enq.fenquiry_id}
                    style={{ textDecoration: "none" }}
                  >
                    <td className="tdfirsttabl">{index + 1}</td>
                    <td>{enq.username}</td>
                    <td>{enq.email}</td>
                    <td>{enq.contact_number}</td>
                    <td>{enq.service_name}</td>
                    <td>{enq.hearabout_us}</td>
                    <td>{enq.enquiry_status}</td>
                    <td onClick={() => pdfGenreter(enq)}>
                      <GrDocumentDownload />
                    </td>
                  </tr>
                ))
              ) : (
                <div className="enquirynodata">No Detail Available Enquiry</div>
              )}
            </tbody>
          </table>
        )}

        <br />
        <br />
        <div>
          <ToastContainer />
        </div>
        {/* Enquiry modal oppen  */}
        <div id="enquiry_popup" style={{ display: "none" }}>
          <div className=" enquiryPopup">
            <div className=" conteners ">
              <div className="" onClick={modal}>
                <Link to="/enquiry">
                  <IoCloseSharp className="IoCloseSharp" />
                </Link>
              </div>
              <div className="flexcontener">
                <div className=" maincontent">
                  <div className="enquirypoptitle">Enquiry - {enquir}</div>
                  <hr />
                  <div className="popupboxleft">
                    Name
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{name}</span>
                  </div>

                  <div className="popupboxleft">
                    Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{email}</span>
                  </div>
                  <div className="popupboxleft">
                    Phone No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{phone}</span>
                  </div>
                  <div className="popupboxleft">
                    Subject&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{subject}</span>
                  </div>
                  <div className="popupboxleft">
                    Service&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{enquiryservice}</span>
                  </div>
                  <div className="popupboxleft">
                    Heardabout&nbsp;&nbsp; -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{hearaboutus}</span>
                  </div>
                  <div className="popupboxleft">
                    Organization&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{organization}</span>
                  </div>
                  <div className="popupboxleft">
                    Message&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{message}</span>
                  </div>
                </div>
                <div class="col-75">
                  <textarea
                    id="subject"
                    name="subject"
                    placeholder="Write Nots about this "
                    style={{ height: "100px" }}
                  ></textarea>
                </div>
                <form
                  onSubmit={(e) => updateEnquiryStatus(e)}
                  autoComplete="off"
                >
                  <div className="buttom-flex">
                    <div>
                      <input
                        className="is-checkradio is-rtl"
                        onChange={(e) => {
                          setEnquirystatus(e.target.value);
                          console.log(enquirystatus);
                        }}
                        value="acknowledgement"
                        id="exampleRtlRadioInline1"
                        type="radio"
                        name="exampleRtlRadioInline"
                      />
                      <label
                        for="exampleRtlRadioInline1"
                        style={{ margin: 20 }}
                      >
                        Acknowledgement
                      </label>
                    </div>
                    <div>
                      <input
                        className="is-checkradio is-rtl"
                        id="exampleRtlRadioInline2"
                        onChange={(e) => setEnquirystatus(e.target.value)}
                        value="working"
                        type="radio"
                        name="exampleRtlRadioInline"
                      />
                      <label
                        for="exampleRtlRadioInline2"
                        style={{ margin: 20 }}
                      >
                        Working
                      </label>
                    </div>
                    <div>
                      <input
                        className="is-checkradio is-rtl"
                        id="exampleRtlRadioInline3"
                        type="radio"
                        onChange={(e) => setEnquirystatus(e.target.value)}
                        value="completed"
                        name="exampleRtlRadioInline"
                      />
                      <label
                        for="exampleRtlRadioInline3"
                        style={{ margin: 20 }}
                      >
                        Completed
                      </label>
                    </div>

                    <div>
                      <input
                        className="is-checkradio is-rtl"
                        id="exampleRtlRadioInline4"
                        type="radio"
                        onChange={(e) => setEnquirystatus(e.target.value)}
                        value="open"
                        name="exampleRtlRadioInline"
                      />
                      <label
                        for="exampleRtlRadioInline4"
                        style={{ margin: 20 }}
                      >
                        Open
                      </label>
                    </div>
                  </div>
                  <div>
                    <button
                      className="submintbutton is-pulled-right"
                      // onClick={() => updateStatus(enquir.enquiry_id)}
                      style={{}}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Enquiry;
