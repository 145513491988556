import React from 'react';

import Dashboard from '../dashboard/dashboard';
import Enquiry from '../enquiry/enquiry';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link } from 'react-router-dom';
import img from '../../images/admin1.png';
import './sidebar.css';


//sadebar componet
const Sidebar = () => {
  return (
    <>
      <div class="sideBarParent">
        <div class="sidebar">
          <div class="dpAndName">
            <img
              className="dpClass"
              src={img}
              id="sidebar-image"
              alt="Placeholder"
            />

            <p class="dpWalaName">Welcome Admin !</p>
          </div>

          <div class="navigationDiv">
            <div class="iconLink">
              <div class="navlink">
                <Link to="/" className="textDecoration">
                  <i className=" bi-house "></i>
                  <p class="linkClass">Dashboard</p>
                </Link>
              </div>
            </div>
            <div class="iconLink">
              <div class="navlink">
                <Link to="/careers" className="textDecoration">
                  <i className=" bi-table"></i>
                  <p class="linkClass">Careers</p>
                </Link>
              </div>
            </div>
            <div class="iconLink">
              <div class="navlink">
                <Link to="/enquiry" className="textDecoration">
                  <i className=" bi-people"></i>
                  <p class="linkClass">Enquiry</p>
                </Link>
              </div>
            </div>

            <div class="iconLink">
              <div class="navlink">
                <Link to="/quotes" className="textDecoration">
                  <i className="  bi-file-earmark-text-fill "></i>
                  <p class="linkClass">Quote Enquiry</p>
                </Link>
              </div>
            </div>
            <div class="iconLink">
              <div class="navlink">
                <Link to="/casestudy" className="textDecoration">
                  <i class="bi bi-briefcase"></i>
                  <p class="linkClass">CaseStudy</p>
                </Link>
              </div>
            </div>
            <div class="iconLink">
              <div class="navlink">
                <Link to="/investment" className="textDecoration">
                  <i class="bi bi-cash-coin"></i>
                  <p class="linkClass">Investment</p>
                </Link>
              </div>
            </div>
            <div class="iconLink">
              <div class="navlink">
                <Link to="/service" className="textDecoration">
                  <i className="fa fa-wrench" aria-hidden="true"></i>
                  <p class="linkClass">Services</p>
                </Link>
              </div>
            </div>
            {/* <div class="iconLink">
              <div class="navlink">
                <Link to="/blog" className="textDecoration">
                  <i className=" bi-shop"></i>

                  <p class="linkClass">Blog</p>
                </Link>
              </div>
            </div> */}
            <div class="iconLink">
              <div class="navlink">
                <Link to="/gitex" className="textDecoration">
                  <i className=" bi-shop"></i>

                  <p class="linkClass">Gitex Banner</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- sidebar parent end --> */}
    </>
  );
};

export default Sidebar;
