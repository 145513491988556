import './Routes.css';
import Sidebar from './component/sidebar/sidebar';
import Dashboard from './component/dashboard/dashboard';
import Enquiry from './component/enquiry/enquiry';
import Careers from './component/career/career';
import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import TopBar from './component/topbar/TopBar';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Login from './component/Login/login';
import AuthService from './component/Login/auth.service';
import authHeader from './component/Login/auth-headers';
import Blogs from './component/blog/AddBlog/Blogs';
import Quotes from './component/Quotes/Quotes';
import urls from './Urls';
import AllBlogs from './component/blog/AllBlogs/AllBlogs';
import BlogEdit from './component/blog/BlogEdit/BlogEdit';
import SingleBlogshow from './component/blog/SingaleBlog/SingleBlogshow';
import Notfound from './component/NotFound/Notfound';
import Service from './component/Service/Service';
import CaseStudy from './component/CaseStudy/CaseStudy';
import Investment from './component/Investment/Investment';
import Hrmain from './HR/Hrmain';
import Gitexbanner from './component/Gitexbanner/Gitexbanner';

const Routes = () => {
  const defaultPage = () => {
    return (
      <div className="zoomParent">
        <TopBar />
        <Sidebar />
        <div className="workArea">
          <Switch>
            <Route exact path="/">
              <Dashboard />
            </Route>
            <Route exact path="/careers">
              <Careers />
            </Route>
            <Route exact path="/enquiry">
              <Enquiry />
            </Route>
            <Route exact path="/service">
              <Service />
            </Route>
            <Route exact path="/blog">
              <Blogs />
            </Route>
            <Route exact path="/quotes">
              <Quotes />
            </Route>
            <Route exact path="/allblogs">
              <AllBlogs />
            </Route>
            <Route exact path="/blogedit/:id">
              <BlogEdit />
            </Route>
            <Route exact path="/singleBlogshow/:id">
              <SingleBlogshow />
            </Route>
            <Route exact path="/casestudy">
              <CaseStudy />
            </Route>
            <Route exact path="/investment">
              <Investment />
            </Route>
            <Route exact path="/gitex">
              <Gitexbanner />
            </Route>
            {/* Hr for test  */}
           
            
            {/* Hr for test  */}
            <Route exact path="*">
              <Notfound />
            </Route>
          </Switch>
        </div>
      </div>
    );
  };
  const handleClose = () => {
    document.getElementById('popupbox').style.display = 'none';
  };
  if (!AuthService.getCurrentUser()) return <Login />;

  return (
    <>
      <Router>
        <div>
          <Switch>
            <Route component={defaultPage} />
            <Route exact path="./hrdashbaord">
              <Hrmain />
            </Route> 
          </Switch>
        </div>
      </Router>
      <div>
        <div id="popupbox" style={{ display: 'none' }}>
          <div className="popupbox ">
            <div className="LogoutPopbox">
              Do You Want To Exit ?
              <div className="logoutbuton">
                <button className="Cancelbtn" onClick={handleClose}>
                  {/* <i className="fa fa-ban" aria-hidden="true"></i> */}
                  Cancel
                </button>
                <br />
                <button className="okbtn" onClick={AuthService.logout}>
                  {/* <i className="fa fa-check-circle-o" aria-hidden="true"></i>  */}
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Routes;
