import React, { useState } from "react";
import AuthService from "./auth.service";
import axios from "axios";
import "./login.css";
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import urls from '../../Urls';
const Login = () => {
  //validation
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  // const onSubmit = (data) => console.log(data);
  //states
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [massage, setMassage] = React.useState("");
  const [status, setStatus] = React.useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const changeUsername = (e) => {
    const name = e.target.value;
    setUsername(name);
  };

  const changePassword = (e) => {
    const pass = e.target.value;
    setPassword(pass);
  };
  const passwordShow = (e) => {
    setPassword(e.target.value);
  };
  // https://cerbosysapi.cerbosys.com/cerbosys/adminLogin
  // adminlogin api
  const onSubmit = (e) => {
    // e.preventDefault();
    axios(`${urls}//adminLogin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then((res) => {
        console.log("response login", res.data);
        setMassage(res.data.message);
        if (res.data.token) {
          setStatus(res.data.success);
          localStorage.setItem("admin", JSON.stringify(res.data));
          toast.success("Login Successfully ", massage);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error("Invalid Username and Password");
        }

        return res.data;
      })
      .catch((err) => {
        toast.error("error happned");
        console.log("error happned");
      });
  };

  return (
    <>
      <div className="grid-container">
        <div class="transparent"></div>
        <form onSubmit={handleSubmit(onSubmit)} autocomplete="off">
          <div className="loginBox">
            <div className="card-title">Sign In</div>
            <div>
              <label for="fname">Username</label>
              <input
                {...register("username", { required: true })}
                className="form-control-loin logincontrol"
                value={username}
                type="text"
                onChange={changeUsername}
                min="4"
              />
              <br />
              <span className="massege">
                {errors.username?.type === "required" &&
                  "Username name is required"}
              </span>
              <br />
              <label for="lname">Password</label>
              <input
                {...register("password", { required: true })}
                className="form-control-pas "
                // onKeyPress={handleKeyPress}
                min="4"
                value={password}
                type={isRevealPwd ? "text" : "password"}
                onChange={(e) => {
                  changePassword(e);
                  passwordShow(e);
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();

                    onSubmit();
                  }
                }}
              />
              <i
                className="eyesicons"
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              >
                {isRevealPwd ? (
                  <i className="fa fa-eye" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-eye-slash" aria-hidden="true"></i>
                )}
              </i>
              <br />
              <span className="massege">
                {errors.password?.type === "required" &&
                  "Password  is required"}
              </span>
            </div>

            <div>
              <button type="submit" className="LoginButton">
                LOG IN
              </button>
            </div>

            {/* <p className="massege">
              <b>{massage}</b>
            </p> */}

            <div className="Contenet">
              By Continuing, you agree to accept our Privacy Policy & Terms of
              Services
            </div>
          </div>
        </form>
      </div>
      <div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Login;
