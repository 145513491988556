import React, { useState, useEffect } from "react";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from "axios";
import urls from "../../Urls";
import authHeader from "../Login/auth-headers";
import LoaderComp from "../Loader/LoaderComp";
import { Link, useHistory } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "./Service.css";
const Service = () => {
  const SERVICIMG_URL = "https://cerbosys.in:3700/myservice/";
  const [service, setService] = React.useState([]);
  const [isloading, setisloading] = useState(undefined);
  let userToken = localStorage.getItem("usertoken");

  // getdata by id
  const [serviceId, setServiceId] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [serviceImage, setServiceImage] = useState("");
  const [imageshowgetbyid, setimageshowgetbyid] = useState("");

  //post service data states
  const [serviceNamepost, setServiceNamepost] = useState("");
  const [errorserviceNamepost, setErrorserviceNamepost] = useState("");
  const [errorserviName, setErrorserviName] = useState(false);
  const [serviceImagePost, setserviceImagePost] = useState("");
  const [imageshow, setImageshow] = useState("");

  const [errorsicon, setErroricon] = useState(false);

  const [errorsicons, setErroricons] = useState("");

  const GetAllService = () => {
    axios.get(urls + "/getAllServices").then((res) => {
      setService(res.data.data);
      setisloading(true);
    });
  };
  useEffect(() => {
    GetAllService();
  }, []);
  // post service data
  const OnServiceName = (e) => {
    const serviceNamepost = e.target.value.replace(/[^a-z]/gi, " ");
    setServiceNamepost(serviceNamepost);
    if (serviceNamepost.length <= 3) {
      setErrorserviceNamepost("Enter serviceName");
      setErrorserviName(true);
    } else {
      setErrorserviName(false);
    }
  };

  const onImageuplode = (e) => {
    const blogimage = e.target.files[0];
    console.log("image", blogimage);
    setImageshow(URL.createObjectURL(e.target.files[0]));
    setserviceImagePost(blogimage);
    document.getElementById("uplodeimag").style.display = "block";

    if (blogimage) {
      setErroricon("provide job icon");
      setErroricons(true);
    } else {
      setErroricon(false);
    }
  };

  //  Job post data with help of  post api coling
  const ServicePost = () => {
    var formdata = new FormData();
    formdata.append("service_name", serviceNamepost);
    formdata.append("service_image", serviceImagePost);
    console.log(formdata);
    fetch(urls + "/insertMyServices", {
      headers: authHeader(),
      method: "post",
      body: formdata,
    })
      .then((res) =>
        res.json().then((formdata) => {
          console.log("Response", formdata);

          toast.success(" Succefully Add Service");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
      )
      .catch((err) => {
        toast.error("Service not post");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };
  // ----------------------post data  end  ------------------
  // Update get bye id
  const hanldeClick = (myservices_id) => {
    axios
      .get(`${urls}/getServicesById?myservice_id=${myservices_id}`, {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log(myservices_id);
        if (res.data.data) {
          const i = res.data.data.length - 1;
          localStorage.getItem(userToken);
          setServiceId(res.data.data[i].myservices_id);
          setServiceName(res.data.data[i].service_name);
          setServiceImage(res.data.data[i].service_image.substr(19));
        }
        document.getElementById("Service_popup").style.display = "block";
        console.log(res.data.data);
      });
  };

  const onchangeImage = (e) => {
    const blogimage = e.target.files[0];
    console.log("image", blogimage);
    setimageshowgetbyid(URL.createObjectURL(e.target.files[0]));
    setServiceImage(blogimage);
    document.getElementById("imageshow").style.display = "block";
    document.getElementById("imageshowcaninapi").style.display = "none";
  };

  // -----upadte bye id
  const UpdateService = () => {
    var formdata = new FormData();
    formdata.append("myservice_id", serviceId);
    formdata.append("service_name", serviceName);
    formdata.append("service_image", serviceImage);

    fetch(urls + "/updateMyServices", {
      headers: authHeader(),
      method: "post",
      body: formdata,
    })
      .then((res) =>
        res.json().then((formdata) => {
          console.log("Response", formdata);
          toast.success("service update sucsefully");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
      )
      .catch((err) => {
        toast.error("something went wrong");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  };

  // close popup model
  const modal = () => {
    document.getElementById("Service_popup").style.display = "none";
  };
  return (
    <>
      <div className="ServicesTopbar">
        <div className="Services1">
          <h3>Services</h3>
        </div>
        <div></div>
        <div></div>
      </div>
      <div className="serviceFlexBox">
        <div className="ServiceLeftcontener">
          {!isloading ? (
            <LoaderComp />
          ) : (
            <table className="table">
              <tr>
                <th className="firsttbalheder">Sr.no</th>
                <th className="lasttbalheder">Service Name</th>
              </tr>

              <tbody>
                {service ? (
                  service.map((Ser, index) => (
                    <tr
                      key={index}
                      onClick={() => hanldeClick(Ser.myservices_id)}
                      id={Ser.myservices_id}
                    >
                      <td className="tdfirsttabl">{index + 1}</td>
                      <td>{Ser.service_name}</td>
                    </tr>
                  ))
                ) : (
                  <div className="container">No Data</div>
                )}
              </tbody>
            </table>
          )}
        </div>
        <div className="ServiceRightcontener">
          <div className="servicePost">
            <div className="field">
              <label className="label" style={{ color: "#000000" }}>
                Service Name
              </label>
              <div className="control">
                <input
                  className="input is-normal  control servic-form-control "
                  type="text"
                  value={serviceNamepost}
                  onChange={OnServiceName}
                  // type="text"
                />
              </div>
              {errorserviName && (
                <small style={{ color: "red", fontSize: 15 }}>
                  {errorserviceNamepost}
                </small>
              )}
            </div>
            <div className="field">
              <label className="label" style={{ color: "#000000" }}>
                Files
              </label>
              <div className="control">
                <input
                  type="file"
                  file={serviceImagePost}
                  id="myFile"
                  name="filename"
                  required
                  multiple={true}
                  className=" control-file form-control "
                  onChange={onImageuplode}
                />
              </div>

              {errorsicon && (
                <small style={{ color: "red", fontSize: 15 }}>
                  {errorsicons}
                </small>
              )}
              <div className="prescription_update_popup_image">
                <img
                  style={{ display: "none" }}
                  id="uplodeimag"
                  alt="."
                  src={imageshow}
                  className="prescriptionImage"
                />
              </div>
            </div>
            <div
              className="form-control-servic "
              style={{ textAlign: "center" }}
            >
              <button
                className="dashbordsubmitbutton"
                onClick={ServicePost}
                disabled={
                  serviceNamepost === "" || serviceImagePost === ""
                    ? true
                    : false
                }
              >
                Post Service
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Popupbox Start */}
      <div id="Service_popup" style={{ display: "none" }}>
        <div className=" enquiryPopup">
          <div className=" conteners ">
            <div className="" onClick={modal}>
              <Link to>
                <IoCloseSharp className="IoCloseSharp" />
              </Link>
            </div>
            <div className="flexcontener">
              <div className=" maincontent">
                <div className="servicePost">
                  <div className="field">
                    <label className="label" style={{ color: "#000000" }}>
                      Service Name
                    </label>
                    <div className="control">
                      <input
                        className="input is-normal  control servic-form-control "
                        type="text"
                        value={serviceName}
                        onChange={(e) => {
                          setServiceName(e.target.value);
                          console.log(serviceName);
                        }}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" style={{ color: "#000000" }}>
                      Files
                    </label>
                    <div className="control">
                      <input
                        type="file"
                        file={serviceImage}
                        id="myFile"
                        name="filename"
                        required
                        multiple={true}
                        className=" control-file form-control "
                        onChange={onchangeImage}
                      />
                    </div>

                    <div className="prescription_update_popup_image">
                      <img
                        id="imageshowcaninapi"
                        style={{ display: "block" }}
                        className="serviceimg"
                        src={SERVICIMG_URL + serviceImage}
                        alt="i"
                      />
                      <img
                        id="imageshow"
                        style={{ display: "none" }}
                        src={imageshowgetbyid}
                        alt="i"
                        className="prescriptionImage"
                      />
                    </div>
                  </div>
                  <div
                    className="form-control-servic "
                    style={{ textAlign: "center" }}
                  >
                    <button
                      className="dashbordsubmitbutton"
                      onClick={UpdateService}
                    >
                      update Service
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Service;
