import React from "react";
import { Circles } from "react-loader-spinner";
import "./loder.css";
const LoaderComp = ({ type, height, color, hidden }) => {
  return (
    <div className="Loaderscontener">
      <div className="loder">
        <Circles
          width="50"
          height="50"
          radius="20"
          color="#05013b"
          ariaLabel="three-dots-loading"
          wrapperStyle
          wrapperClass
        />
      </div>
      <div>Loading...</div>
    </div>
  );
};

export default LoaderComp;
