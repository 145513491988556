import React, { useState, useEffect } from 'react';
import urls from '../../Urls';
import axios from 'axios';
import authHeader from '../Login/auth-headers';
import { Link } from 'react-router-dom';
import { IoCloseSharp } from 'react-icons/io5';
import './quotes.css';
import CerboLogo from '../../images/logo.png';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderComp from '../Loader/LoaderComp';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { GrDocumentDownload } from 'react-icons/gr';
import jsPDF from 'jspdf';
const Quotes = () => {
  const [isloading, setisloading] = useState(undefined);

  const [data, setData] = useState([]);

  const [quotesid, setQuotesid] = useState('');
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [service, setService] = useState('');
  const [message, setMessage] = useState('');
  const [organization, setOrganization] = useState("");
  const [quotesstatus, setQuotesstatus] = useState('');
  let userToken = localStorage.getItem('usertoken');

  const fetchData = async () => {
    await axios
      .get(`${urls}//getAllQuote`)
      .then((res) => {
        const response = res.data.data;

        setData(response);
        setisloading(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // select search Function
  function select(event) {
    // console.log(event);
    if (event.target.value === '1') {
      // alert("1")
      fetch(`${urls}/getAllQuote`, {
        headers: authHeader(),
      })
        .then((res) => res.json())
        .then((res) => {
          setData(res.data);
          // alert(res.message);

          console.log('Enquiry', res.data);
        });
    } else if (event.target.value === '2') {
      // alert("2")
      fetch(`${urls}/filterQuoteEnquiry?quote_status=Open`, {
        headers: authHeader(),
      })
        .then((res) => res.json())
        .then((res) => {
          setData(res.data);
          // alert(res.message);

          console.log('open', res);
        });
    } else if (event.target.value === '3') {
      // alert("3")
      fetch(`${urls}/filterQuoteEnquiry?quote_status=aknowledgement`, {
        headers: authHeader(),
      })
        .then((res) => res.json())
        .then((res) => {
          setData(res.data);
          // alert(res.message);

          console.log('acknowledged', res);
        });
    } else if (event.target.value === '4') {
      // alert("4");
      fetch(`${urls}/filterQuoteEnquiry?quote_status=working`, {
        headers: authHeader(),
      })
        .then((res) => res.json())
        .then((res) => {
          setData(res.data);
          // alert(res.message);
          console.log('working', res);
        });
    } else {
      // alert("5")
      fetch(`${urls}/filterQuoteEnquiry?quote_status=completed`, {
        headers: authHeader(),
      })
        .then((res) => res.json())
        .then((res) => {
          setData(res.data);
          // alert(res.message);
          console.log('completed', res);
        });
    }
  }
  const hanldeClick = async (quote_id) => {
    console.log(quote_id);
    axios
      .get(`${urls}//getQuoteById?quote_id=${quote_id}`, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data.data) {
          const i = res?.data?.data.length - 1;
          localStorage.getItem(userToken);
          setQuotesid(res?.data?.data[i].quote_id);
          setFullname(res?.data?.data[i].fullname);
          setEmail(res?.data?.data[i].email);
          setPhone(res?.data?.data[i].contact_number);
          setService(res?.data?.data[i].service);
          setOrganization(res?.data?.data[i].organization_name);
          setMessage(res?.data?.data[i].message);
          setQuotesstatus(res?.data?.data[i].enquirystatus);
        }
        document.getElementById('enquiry_popup').style.display = 'block';
        console.log(res.data.data);
      });
  };

  // update Enquiry function

  const QuotesStatus = async (e) => {
    e.preventDefault();
    axios
      .post(
        `${urls}/updateQuoteStatus`,
        {
          quote_status: quotesstatus,
          quote_id: quotesid,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        setQuotesstatus('');
        console.log('update Quotesstatus data', res);
        // alert("update Quotesstatus data");
        document.getElementById('enquiry_popup').style.display = 'none';
        window.location.reload();
        toast.success(' Quotes status update  sucsefully');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        toast.error('something went wrong');
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };
  // close popup model
  const modal = () => {
    document.getElementById('enquiry_popup').style.display = 'none';
  };

  // dawnlod pdf start
  const pdfGenreter = (item) => {
    const doc = new jsPDF();
    console.log(item);
    // doc.addImage({item.})
    // doc.setFontSize(22);
    doc.addImage(CerboLogo, 'PNG', 50, 50, 50, 30);
    // doc.setTextColor(255, 56, 6);
    // doc.text(40, 20, 'Cerbosys ');
    // doc.text(40, 40, 'Enquiry');

    doc.setFont('poppins ', 'bold');
    doc.text('Name', 50, 100);
    doc.text('Email.', 50, 110);
    doc.text('Phone No', 50, 120);
    doc.text('obtained', 50, 130);
    doc.text('Date', 50, 140);
    doc.text('Status', 50, 150);
    doc.setFont('poppins ', 'Normal');
    doc.text(`${item.fullname}`, 80, 100);
    doc.text(`${item.email}`, 80, 110);
    doc.text(`${item.contact_number}`, 80, 120);
    doc.text(`${item.obtained_from}`, 80, 130);
    doc.text(`${item.creationDate.substr(0, 10)}`, 80, 140);
    doc.text(`${item.quote_status}`, 80, 150);
    doc.save(`Quotes_${item.fullname}.pdf`);
  };
  // dawnlod pdf End

  return (
    <>
      <div>
        <div className=" QutotesTop">
          <div className="">
            <h6 className="Qutotestitle"> Quotes</h6>
          </div>
          <div></div>

          <div className="">
            <select onChange={select} className="QutotesSelectflter">
              <option value="1">All</option>
              <option value="2">Open</option>
              <option value="3">Acknowledged</option>
              <option value="4">Working</option>
              <option value="5">Completed</option>
            </select>
          </div>
          <div className="">
            <ReactHtmlTableToExcel
              id="test-table-xls-button"
              className="expots"
              table="table-to-xls"
              filename="Quotes"
              sheet="Quotes"
              buttonText="as XLS"
            />
          </div>
        </div>
        {!isloading ? (
          <LoaderComp />
        ) : (
          <table className="table" id="table-to-xls">
            <thead>
              <tr>
                <th className="firsttbalheder">S No.</th>
                <th>Full Name</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Obtained</th>
                <th>Date</th>
                <th>Status</th>
                <th className="lasttbalheder">Download</th>
              </tr>
            </thead>
            <tbody>
              {data ? (
                data.map((item, index) => (
                  <tr
                    key={index}
                    onClick={() => hanldeClick(item.quote_id)}
                    id={item.quote_id}
                    style={{ texthecoration: 'none' }}
                  >
                    <td className="tdfirsttabl">{index + 1}</td>
                    <td>{item.fullname}</td>
                    <td>{item.contact_number}</td>
                    <td>{item.email}</td>
                    <td>{item.obtained_from}</td>
                    <td>{item.creationDate.substr(0, 10)}</td>

                    <td>{item.quote_status}</td>
                    <td onClick={() => pdfGenreter(item)}>
                      <GrDocumentDownload />
                    </td>
                  </tr>
                ))
              ) : (
                <div className="container">No Detail Available</div>
              )}
            </tbody>
          </table>
        )}
        <div>
          <ToastContainer />
        </div>
      </div>

      {/* Quotes popup box start */}

      <div id="enquiry_popup" style={{ display: 'none' }}>
        <div className=" enquiryPopup">
          <div className=" conteners ">
            <div className="" onClick={modal}>
              <Link to="/quotes">
                <IoCloseSharp className="IoCloseSharp" />
              </Link>
            </div>
            <div className="flexcontener">
              <div className=" maincontent">
                <div className="enquirypoptitle">Quotes - {quotesid}</div>
                <hr />
                <div className="popupboxleft">
                  Fullname&nbsp;&nbsp;&nbsp; -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="popupboxright">{fullname}</span>
                </div>

                <div className="popupboxleft">
                  Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="popupboxright">{email}</span>
                </div>
                <div className="popupboxleft">
                  Phone No.&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="popupboxright">{phone}</span>
                </div>
                <div className="popupboxleft">
                  Organization&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="popupboxright">{organization}</span>
                </div>
                <div className="popupboxleft">
                  Message&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="popupboxright">{message}</span>
                </div>
              </div>
              <div class="col-75">
                <textarea id="subject" name="subject" placeholder="Write Nots about this "
                style={{height:'100px'}}></textarea>
              </div>
              <form onSubmit={(e) => QuotesStatus(e)} autoComplete="off">
                <div className="buttom-flex">
                  <div>
                    <input
                      className="is-checkradio is-rtl"
                      onChange={(e) => {
                        setQuotesstatus(e.target.value);
                        console.log(quotesstatus);
                      }}
                      value="acknowledgement"
                      id="exampleRtlRadioInline1"
                      type="radio"
                      name="exampleRtlRadioInline"
                    />
                    <label for="exampleRtlRadioInline1" style={{ margin: 20 }}>
                      Acknowledgement
                    </label>
                  </div>
                  <div>
                    <input
                      className="is-checkradio is-rtl"
                      id="exampleRtlRadioInline2"
                      onChange={(e) => setQuotesstatus(e.target.value)}
                      value="working"
                      type="radio"
                      name="exampleRtlRadioInline"
                    />
                    <label for="exampleRtlRadioInline2" style={{ margin: 20 }}>
                      Working
                    </label>
                  </div>
                  <div>
                    <input
                      className="is-checkradio is-rtl"
                      id="exampleRtlRadioInline3"
                      type="radio"
                      onChange={(e) => setQuotesstatus(e.target.value)}
                      value="completed"
                      name="exampleRtlRadioInline"
                    />
                    <label for="exampleRtlRadioInline3" style={{ margin: 20 }}>
                      Completed
                    </label>
                  </div>

                  <div>
                    <input
                      className="is-checkradio is-rtl"
                      id="exampleRtlRadioInline4"
                      type="radio"
                      onChange={(e) => setQuotesstatus(e.target.value)}
                      value="open"
                      name="exampleRtlRadioInline"
                    />
                    <label for="exampleRtlRadioInline4" style={{ margin: 20 }}>
                      Open
                    </label>
                  </div>
                </div>
                <div>
                  <button className="submintbutton is-pulled-right">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Quotes popup box End  */}
    </>
  );
};

export default Quotes;
