import React from 'react'
import Hrsaidbar from './Hrsaidbar/Hrsaidbar'
import { Route, Switch } from 'react-router-dom'
import Careers from './career/career'
import Notfound from './NotFound/Notfound'
import Addcareer from './career/Addcareer'

const Hrmain = () => {
  return (
    <div> 
        <Hrsaidbar/>
         <Switch>
            <Route exact path="/hr/careers">
                <Careers/>
            </Route>
            <Route exact path="/hr/addcareer">
                <Addcareer/>
            </Route>
            <Route exact path="*">
              <Notfound />
            </Route>
            </Switch>
       
    </div>
  )
}

export default Hrmain