import React from 'react'
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link } from 'react-router-dom';
import img from '../HrImages/Samita.png';
import '../../component/sidebar/sidebar.css';
const Hrsaidbar = () => {
  return (
    <>
    <div class="sideBarParent">
      <div class="sidebar">
        <div class="dpAndName">
          <img
            className="dpClass"
            src={img}
            id="sidebar-image"
            alt="Placeholder"
          />

          <p class="dpWalaName">Welcome HR !</p>
        </div>

        <div class="navigationDiv">
          <div class="iconLink">
            <div class="navlink">
              <Link to="/" className="textDecoration">
                <i className=" bi-house "></i>
                <p class="linkClass">Dashboard</p>
              </Link>
            </div>
          </div>
          <div class="iconLink">
            <div class="navlink">
              <Link to="/careers" className="textDecoration">
                <i className=" bi-table"></i>
                <p class="linkClass">Careers</p>
              </Link>
            </div>
          </div>
          <div class="iconLink">
            <div class="navlink">
              <Link to="/hr/addcareer" className="textDecoration">
                <i className=" bi-people"></i>
                <p class="linkClass">Addcareer</p>
              </Link>
            </div>
          </div>

       
        </div>
      </div>
    </div>
    {/* <!-- sidebar parent end --> */}
  </>
  )
}

export default Hrsaidbar