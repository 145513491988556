// import All dependency to use this file
import React, { useEffect, useState } from "react";
import axios from "axios";
import authHeader from "../Login/auth-headers";
import { Link } from "react-router-dom";
import urls from "../../Urls";

import "./careeer.css";
import img1 from "../../images/flutter.jpg";
import img2 from "../../images/logo.svg";
import exp from "../icons/ic_work_24px.svg";
import Rupees from "../icons/Rupees.svg";
import hands from "../icons/hands.svg";
import place from "../icons/ic_place_24px.svg";
import parser from "html-react-parser";
import { MdDelete } from "react-icons/md";
import { MdFileDownload } from "react-icons/md";
import { RiFileTextFill } from "react-icons/ri";
import { saveAs } from "file-saver";
import { IoCloseSharp } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoaderComp from "../Loader/LoaderComp";
import { GrDocumentDownload } from "react-icons/gr";
import jsPDF from "jspdf";
import CerboLogo from "../../images/logo.png";
import ReactHtmlTableToExcel from "react-html-table-to-excel";

const Careers = () => {
  const IMG = "https://cerbosys.in:3700/job_post/";
  // All states for use in this file
  const [data, setData] = React.useState([]);
  const [job, setJob] = React.useState([]);
  const [sidebox, setsidebox] = useState([]);
  console.log(sidebox);
  // var tesing = [];
  // tesing = Image.split("/");
  // console.log("tesing", tesing);
  let userToken = localStorage.getItem("usertoken");
  const [jobpostid, setJobpostid] = useState("");
  const [jobposition, setJobposition] = useState("");
  const [joblocation, setJoblocation] = useState("");
  const [jobemploymenttype, setJobemploymenttype] = useState("");
  const [jobexperience, setJobexperience] = useState("");
  const [salaryscale, setSalaryscale] = useState("");
  const [jobdescription, setJobdescription] = useState("");
  const [careerid, setCareerid] = useState("");
  const [fullname, setfullname] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [resume, setResume] = useState("");
  const [careerstatus, setcareerstatus] = useState("");
  const [isloading, setisloading] = useState(undefined);
  const [TotalCurrentOpenings, setTotalCurrentOpenings] = useState("");

  // All api colings from FE To BE
  const getData = () => {
    // this is for getAllCareer for career page  of second table
    axios.get(urls + "/getAllCareer", { headers: authHeader() }).then((res) => {
      setData(res.data.data);
      setisloading(true);

      console.log("length", res.data.data);
    });
    // this is for getAllJobPost for career page  of first table

    axios
      .get(urls + "/getAllJobPost", {
        headers: authHeader(),
      })
      .then((res, i) => {
        console.log("getAllJobPost", res.data.data);
        setJob(res.data.data);
        setsidebox(res.data.data);
        setisloading(true);
      });
    // this is for TotalCurrentOpenings for dashbord card in to of

    // axios
    //   .get(urls + "/getTotalCurrentOpenings", { headers: authHeader() })
    //   .then((res) => {
    //     setTotalCurrentOpenings({
    //       TotalCurrentOpenings: res.data.data[0].Total,
    //     });
    //   });
  };

  useEffect(() => {
    getData();
  }, []);

  // this functionuse for top of dropdawn Search filter
  function select(event) {
    // console.log(event);
    if (event.target.value === "1") {
      // alert("1")
      fetch(`${urls}/getAllCareer`, {
        headers: authHeader(),
      })
        .then((res) => res.json())
        .then((res) => {
          setData(res.data);

          // alert(res.message);

          console.log("Career", res.data);
        });
    } else if (event.target.value === "2") {
      // alert("2")
      fetch(`${urls}/filterCareer?career_status=open`, {
        headers: authHeader(),
      })
        .then((res) => res.json())
        .then((res) => {
          setData(res.data);

          // alert(res.message);

          console.log("open", res);
        });
    } else if (event.target.value === "3") {
      // alert("3")
      fetch(`${urls}/filterCareer?career_status=acknowledged`, {
        headers: authHeader(),
      })
        .then((res) => res.json())
        .then((res) => {
          setData(res.data);

          // alert(res.message);

          console.log("acknowledged", res);
        });
    } else if (event.target.value === "4") {
      // alert("4");
      fetch(`${urls}/filterCareer?career_status=working`, {
        headers: authHeader(),
      })
        .then((res) => res.json())
        .then((res) => {
          setData(res.data);

          // alert(res.message);
          console.log("working", res);
        });
    } else {
      // alert("5")
      fetch(`${urls}/filterCareer?career_status=completed`, {
        headers: authHeader(),
      })
        .then((res) => res.json())
        .then((res) => {
          setData(res.data);

          // alert(res.message);
          console.log("completed", res);
        });
    }
  }
  const saveFile = () => {
    saveAs(
      `https://cerbosys.in:3700/resume/${resume.substring(16)}`,
      `${fullname}.pdf`
    );
  };
  const hanldeClick = async (career_id) => {
    axios
      .get(`${urls}//getCareerById?career_id=${career_id}`, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(career_id);
        if (res.data.data) {
          const i = res?.data?.data.length - 1;
          localStorage.getItem(userToken);
          setCareerid(res?.data?.data[i].career_id);
          setfullname(res?.data?.data[i].fullname);
          setPosition(res?.data?.data[i].positionapplied);
          setEmail(res?.data?.data[i].email);
          setPhone(res?.data?.data[i].mobilenumber);
          setResume(res?.data?.data[i].resume);
          setMessage(res?.data?.data[i].resume_description);
          setcareerstatus(res?.data?.data[i].career_status);
        }
        document.getElementById("career_popup").style.display = "block";
        console.log(res.data.data);
      });
  };

  // update career function

  const careerUpdate = async (e) => {
    e.preventDefault();
    axios
      .post(
        `${urls}//updateCareerStatus`,
        {
          career_status: careerstatus,
          career_id: careerid,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        setcareerstatus("");
        console.log("update careerstatus data", res);
        // alert("update careerstatus data");
        document.getElementById("career_popup").style.display = "none";
        toast.success("update Carrer status");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        toast.error("Not update status");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };

  // jobpost get in popup
  const getcareerdatabyid = async (jobpost_id) => {
    axios
      .get(`${urls}//getJobPostById?jobpost_id=${jobpost_id}`, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res);
        if (res.data.data) {
          const i = res?.data?.data.length - 1;
          localStorage.getItem(userToken);
          setJobpostid(res?.data?.data[i].jobpost_id);
          setJobposition(res?.data?.data[i].jobposition);
          setJoblocation(res?.data?.data[i].joblocation);
          setJobemploymenttype(res?.data?.data[i].jobemploymenttype);
          setJobexperience(res?.data?.data[i].jobexperience);
          setSalaryscale(res?.data?.data[i].salaryscale);
          setJobdescription(res?.data?.data[i].jobdescription);
        }
        document.getElementById("careerJonb_popup").style.display = "block";
        console.log(res.data.data);
      });
  };
  // job post delete popup
  const DeleteJob = async (jobpostid) => {
    console.log(jobpostid);
    axios
      .get(`${urls}//deleteJobPost?jobpost_id=${jobpostid}`, {
        headers: authHeader(),
      })
      .then((res) => {
        // alert("Deleted successfully");
        document.getElementById("careerJonb_popup").style.display = "none";
        // window.location.reload();
        toast.success("Deleted successfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        toast.error("Samthing Went Worng");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };
  // close popup model
  const modal = () => {
    document.getElementById("careerJonb_popup").style.display = "none";
    document.getElementById("career_popup").style.display = "none";
  };

  // dawnlod pdf start
  const pdfGenreter1 = (car) => {
    const doc = new jsPDF();
    console.log(car);
    // doc.addImage({item.})
    // doc.setFontSize(22);
    doc.addImage(CerboLogo, "PNG", 50, 50, 50, 30);
    // doc.setTextColor(255, 56, 6);
    // doc.text(40, 20, 'Cerbosys ');
    // doc.text(40, 40, 'Enquiry');

    doc.setFont("poppins ", "bold");
    doc.text("location", 50, 100);
    doc.text("position.", 50, 110);
    doc.text("Experience", 50, 120);
    doc.text("Employment", 50, 130);

    doc.setFont("poppins ", "Normal");
    doc.text(`${car.joblocation}`, 80, 100);
    doc.text(`${car.jobposition}`, 80, 110);
    doc.text(`${car.jobexperience}`, 80, 120);
    doc.text(`${car.jobemploymenttype}`, 80, 130);
    doc.save(`Position_${car.joblocation}.pdf`);
  };
  // dawnlod pdf End

  // dawnlod pdf start
  const pdfGenreter2 = (car) => {
    const doc = new jsPDF();
    console.log(car);
    // doc.addImage({item.})
    // doc.setFontSize(22);
    doc.addImage(CerboLogo, "PNG", 50, 50, 50, 30);
    // doc.setTextColor(255, 56, 6);
    // doc.text(40, 20, 'Cerbosys ');
    // doc.text(40, 40, 'Enquiry');

    doc.setFont("poppins ", "bold");
    doc.text("Name", 50, 100);
    doc.text("Email.", 50, 110);
    doc.text("Phone No", 50, 120);
    doc.text("position", 50, 130);

    doc.setFont("poppins ", "Normal");
    doc.text(`${car.fullname}`, 80, 100);
    doc.text(`${car.email}`, 80, 110);
    doc.text(`${car.mobilenumber}`, 80, 120);
    doc.text(`${car.positionapplied}`, 80, 130);

    doc.save(`Apply_${car.fullname}.pdf`);
  };
  // dawnlod pdf End

  return (
    <>
      <div className="Career-Flex-Contener">
        <div className="CareerLeftContenetr">
          <div className=" CareerTop">
            <div className="">
              <h6 className="Careertitle">Job Post</h6>
            </div>
            <div className="">
              <select onChange={select} className="CareerSelectflter">
                <option value="1">All</option>
                <option value="2">Open</option>
                <option value="3">Acknowledged</option>
                <option value="4">Working</option>
                <option value="5">Completed</option>
              </select>
            </div>
          </div>
          {!isloading ? (
            <LoaderComp />
          ) : (
            <table className="table carrer-tabl">
              <thead>
                <tr className="table--tr">
                  <th className="firsttbalheder">Sr.no</th>
                  <th>Location</th>
                  <th>Position</th>
                  <th>Experience</th>
                  <th> Type</th>
                  <th className="lasttbalheder">Download</th>
                </tr>
              </thead>

              <tbody>
                {job ? (
                  job.map((car, index) => {
                    return (
                      <tr
                        className="tabl-td"
                        key={index}
                        onClick={() => getcareerdatabyid(car.jobpost_id)}
                        id={car.jobpost_id}
                        style={{ textDecoration: "none" }}
                      >
                        <td className="tdfirsttabl">{index + 1}</td>
                        <td>{car.joblocation}</td>
                        <td>{car.jobposition}</td>
                        <td>{car.jobexperience}</td>
                        <td>{car.jobemploymenttype}</td>
                        <td onClick={() => pdfGenreter1(car)}>
                          <GrDocumentDownload />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <h4>No Open Positions</h4>
                )}
              </tbody>
            </table>
          )}

          <br />
          <br />
          <div className="CareerTop">
            <div>
              <h1 className="Careertitle">Application</h1>
            </div>
            <div></div>
            <div className="">
              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="expots"
                table="table-to-xls"
                filename="Application"
                sheet="Application"
                buttonText="as XLS"
              />
            </div>
          </div>

          {!isloading ? (
            <LoaderComp />
          ) : (
            <table className="table " id="table-to-xls">
              <thead>
                <tr>
                  <th className="firsttbalheder">Sr.no</th>
                  <th>Name</th>
                  <th>Position</th>
                  <th>Mobile </th>
                  <th>Email</th>
                  <th>status</th>
                  <th className="lasttbalheder">Download</th>
                </tr>
              </thead>
              {data ? (
                <tbody>
                  {data.map((car, index) => (
                    <tr
                      key={index}
                      onClick={() => hanldeClick(car.career_id)}
                      id={car.career_id}
                      style={{ textDecoration: "none" }}
                    >
                      <td className="tdfirsttabl">{index + 1}</td>
                      <td>{car.fullname}</td>
                      <td>{car.positionapplied}</td>
                      <td>{car.mobilenumber}</td>
                      <td>{car.email}</td>
                      <td>{car.career_status}</td>
                      <td onClick={() => pdfGenreter2(car)}>
                        <GrDocumentDownload />
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <div className=" container">
                  <h4>No Open application</h4>
                </div>
              )}
            </table>
          )}
        </div>
        <div className="CareerRightContener">
          <div className="postion">
            {TotalCurrentOpenings.TotalCurrentOpenings}&nbsp;&nbsp;Postion
          </div>
          <div className="jobcard_div">
            {sidebox ? (
              sidebox.map((item, index) => {
                return (
                  <div key={index} className="inner-card-div">
                    <div className="left-card-div">
                      <div className="careercardimages">
                        <img
                          className="card-image"
                          src={IMG + `${item.job_icon.substr(18)}`}
                          alt="img"
                        />
                      </div>
                    </div>
                    <div className="right-card-div">
                      <div className="card-main-heading">
                        {item.jobposition}
                      </div>
                      <div className="right-center-div">
                        <div className="card-heading-icon">
                          <img
                            className="card-icon"
                            src={exp}
                            alt="Placeholder"
                          />
                        </div>
                        <div className="card-heading-content">
                          Exp. min {item.jobexperience} years
                        </div>
                      </div>

                      <div className="right-center-div">
                        <div className="card-heading-icon">
                          <img
                            className="card-icon"
                            src={Rupees}
                            alt="Placeholder"
                          />
                        </div>
                        <div className="card-heading-content">
                          Salary {item.salaryscale} /Months
                        </div>
                      </div>

                      <div className="right-center-div">
                        <div className="card-heading-icon">
                          <img
                            className="card-icon"
                            src={hands}
                            alt="Placeholder"
                          />
                        </div>
                        <div className="card-heading-content">
                          {item.jobemploymenttype}
                        </div>
                      </div>

                      <div className="right-center-div">
                        <div className="card-heading-icon">
                          <img
                            className="card-icon"
                            src={place}
                            alt="Placeholder"
                          />
                        </div>
                        <div className="card-heading-content">
                          {item.joblocation}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <h4
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "40px",
                  marginTop: "50px",
                }}
              >
                No Open Positions
              </h4>
            )}
          </div>
          <br />
          <br />
        </div>

        {/* Carrespopupbox career start */}
        <div id="career_popup" style={{ display: "none" }}>
          <div className=" enquiryPopup">
            <div className=" conteners ">
              <div className="" onClick={modal}>
                <Link to="/careers">
                  <IoCloseSharp className="IoCloseSharp" />
                </Link>
              </div>
              <div className="flexcontener">
                <div className=" maincontent">
                  <div className="enquirypoptitle">Career -{careerid}</div>
                  <hr />
                  <div className="popupboxleft">
                    Full Name&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright"> {fullname}</span>
                  </div>
                  <div className="popupboxleft">
                    Position&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{position}</span>
                  </div>
                  <div className="popupboxleft">
                    Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright"> {email}</span>
                  </div>
                  <div className="popupboxleft">
                    Phone
                    No.&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{phone}</span>
                  </div>
                  <div className="popupboxleft">
                    Resume&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright" onClick={saveFile}>
                      <RiFileTextFill />
                      &nbsp;{resume.substring(16)}&nbsp;&nbsp;&nbsp;
                      <MdFileDownload />
                    </span>
                  </div>
                  <div className="popupboxleft">
                    Message&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{message}</span>
                  </div>
                </div>
                <div class="col-75">
        <textarea id="subject" name="subject" placeholder="Write Nots about this "
         style={{height:'100px'}}></textarea>
      </div>
                <form onSubmit={(e) => careerUpdate(e)} autoComplete="off">
                  <div className="buttom-flex">
                    <div>
                      <input
                        className="is-checkradio is-rtl"
                        onClick={(e) => setcareerstatus(e.target.value)}
                        value="acknowledgement"
                        id="exampleRtlRadioInline1"
                        type="radio"
                        name="exampleRtlRadioInline"
                      />
                      <label
                        for="exampleRtlRadioInline1"
                        style={{ margin: 20 }}
                      >
                        acknowledgement
                      </label>
                      <input
                        className="is-checkradio is-rtl"
                        onClick={(e) => setcareerstatus(e.target.value)}
                        value="completed"
                        id="exampleRtlRadioInline3"
                        type="radio"
                        name="exampleRtlRadioInline"
                      />
                      <label
                        for="exampleRtlRadioInline3"
                        style={{ margin: 20 }}
                      >
                        Completed
                      </label>
                      <input
                        className="is-checkradio is-rtl"
                        onClick={(e) => setcareerstatus(e.target.value)}
                        value="open"
                        id="exampleRtlRadioInline4"
                        type="radio"
                        name="exampleRtlRadioInline"
                      />
                      <label
                        for="exampleRtlRadioInline4"
                        style={{ margin: 20 }}
                      >
                        Open
                      </label>
                      <input
                        className="is-checkradio is-rtl"
                        onClick={(e) => setcareerstatus(e.target.value)}
                        value="rejected"
                        id="exampleRtlRadioInline2"
                        type="radio"
                        name="exampleRtlRadioInline"
                      />
                      <label
                        for="exampleRtlRadioInline2"
                        style={{ margin: 20 }}
                      >
                        Rejected
                      </label>
                    </div>
                  </div>
                  <div>
                    <button className="submintbutton is-pulled-right">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Carrespopupbox career End */}

        {/* job post delet popup start */}

        <div id="careerJonb_popup" style={{ display: "none" }}>
          <div className="career_Job-post_delete">
            <div className="careerpopupcontener">
              <div onClick={modal}>
                <Link to="/careers">
                  <IoCloseSharp className="IoCloseSharp" />
                </Link>
              </div>
              <div className="flexcontener">
                <div className=" maincontent">
                  <div className="enquirypoptitle">
                    Position ID -{jobpostid}
                  </div>
                  <hr />
                  <div className="popupboxleft">
                    Position&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright"> {jobposition}</span>
                  </div>
                  <div className="popupboxleft">
                    Location&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    -&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright"> {joblocation}</span>
                  </div>
                  <div className="popupboxleft">
                    Experience&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{jobexperience}</span>
                  </div>
                  <div className="popupboxleft">
                    Employment Type &nbsp;&nbsp; -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">{jobemploymenttype}</span>
                  </div>
                  <div className="popupboxleft">
                    Salary
                    Scale&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright"> {salaryscale}</span>
                  </div>
                  <div className="popupboxleft">
                    Job
                    Description&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="popupboxright">
                      {" "}
                      {parser(jobdescription)}{" "}
                    </span>
                  </div>
                </div>
                <button>
                  <div
                    className="deletepopupbutton"
                    onClick={() => {
                      DeleteJob(jobpostid);
                    }}
                  >
                    <MdDelete />
                    &nbsp;&nbsp;&nbsp;Delete job
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* job post delet popup End */}
      </div>
      <div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Careers;
